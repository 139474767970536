import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS } from "../../Constants/icons";
import { GetConsultation } from "../../Controllers/Modals/GetConsultation";
import { Button } from "../Components/Button";
import { ModalSuit } from "./ModalSuit";


class AskQuestionModal extends ViewItem {

    get controller(): GetConsultation {
        return this.props.controller
    }

    render(){
        const { hide, visible, title } = this.controller
        return <ModalSuit title={title} hide={hide} visible={visible}>
                <span className="modal-title">Ask a question</span>
                <span className="modal-description">Have a question on your mind? We're here to help! Just fill out the fields below. Our team is dedicated to providing you with the answers and information you need. Ask away, and we'll get back to you promptly!</span>
                <div className="modal-input-box">
                    <input 
                        name="name"
                        placeholder="Enter your name"
                        className="modal-input"
                    />
                </div>
                <div className="modal-input-box">
                    <input 
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        className="modal-input"
                    />
                </div>
                <div className="modal-input-box">
                    <input 
                        name="subject"
                        placeholder="Subject"
                        className="modal-input"
                    />
                </div>
                <div className="modal-input-box">
                    <textarea 
                        name="content"
                        placeholder="Type your question here"
                        className="modal-input modal-textarea"
                    />
                </div>
                <div className="modal-submit">
                    <Button 
                        title="Submit"
                        onClick={()=>{}}
                        isSubmit
                        className="submit-button"
                        icon={BUTTONICONS.right}
                    />
                </div>
            </ModalSuit>
    }
}

export { AskQuestionModal }