import { Model } from "../Base/Model";


class ScrollController extends Model {


    scroll = (some: number) => {
        window.scroll(0, some)
    }
}

export { ScrollController }