import { PageView } from "../../Base/PageView";
import { ContertopsPage } from "../../Controllers/Pages/ContertopsPage";
import { Button } from "../Components/Button";
import { CarouselView } from "../Components/CarouselVIew/CarouselView";
import { InspirationView } from "../Components/InspirationView/InspirationView";
import { TitleView } from "../Components/TitleView";
import { TypesBoxView } from "../Components/TypesBox/TypesBoxView";
import { VideosBoxView } from "../Components/VideoBox/VideosBoxView";
import { ConsultationBox } from "./PageComponent/ConsultationBox";
import { ContactBox } from "./PageComponent/ContactBox";

class ContertopsPageView extends PageView {

    get controller(): ContertopsPage {
        return this.props.controller
    }
    render(){
        const { carousel, videosBox, inspiration, types, playCurrentVideo, showGallery} = this.controller
        return <div className="page">
            <CarouselView ref={carousel.set} controller={carousel} />
            <TitleView text="About Contertops">
                <Button 
                    onClick={playCurrentVideo}
                    title="Watch video"
                    className="helper-button"
                />
                <Button 
                    onClick={showGallery}
                    title="See examples"
                    className="helper-button"
                />
            </TitleView>
            <VideosBoxView ref={videosBox.set} controller={videosBox}/>
            <TitleView text="Choose your type"/>
            <TypesBoxView ref={types.set} controller={types} />
            <TitleView text="Inspiring Installations">
                <Button 
                    onClick={showGallery}
                    title="Open gallery"
                    className="helper-button"
                />
            </TitleView>
            <InspirationView 
                ref={inspiration.set}
                controller={inspiration}
            />
            <ConsultationBox 
                text="Unlock your kitchen transformation with a free consultation. Let's discuss your vision and make it a reality."
            />
            <ContactBox/>
        </div>
    }
}

export { ContertopsPageView }