export const UPDATE = {
  LANG: 'lang',
  DICTIONARY: 'dictionary',
  USERINFO: 'userinfo',
  LOADER: 'loader',
  AUTH: 'auth',
  ALERT: 'alert',
  MENU: 'menu',
  APP_NAVIGATION: 'app_navigation',
};
