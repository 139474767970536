import { Model } from "../../Base/Model";

export type carouselItemModel = {
    title: string;
    description: string;
    image: string;
    selected?: boolean;
}

class CarouselItem extends Model {
    private _model: carouselItemModel
    private _selected: boolean;
    private _posRef: any
    private _offsetLeft: number = 0

    constructor(model: carouselItemModel){
        super()
        this._model = model
        this._selected = model.selected || false
    }

    get title(){
        return this._model.title
    }

    get description(){
        return this._model.description
    }

    get image(){
        return this._model.image
    }

    get selected(){
        return this._selected
    }
    setSelected = (bool: boolean) => {
        if(this._selected === bool){
            return 
        }
        this._selected = bool
        this.updateMe()
    }
    setPosRef = (e: any) => {
        // console.error("POSLEFT", e.offsetLeft)
        this._posRef = e
        this._offsetLeft = this._posRef && this._posRef.offsetLeft ? this._posRef.offsetLeft : 0
    }

    get offsetLeft() {
        return this._offsetLeft
    }
}

export { CarouselItem }