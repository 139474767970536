import { Model } from "../../Base/Model";
import { controllers } from "../../Controllers/Controllers";
import { ScrollController } from "../ScrollController";
import { OneType, oneTypeModel } from "./OneType";

type typesBoxModel = {
    datas: Array<oneTypeModel>
}

class TypesBox extends Model {
    private _model: typesBoxModel
    private _types: Array<OneType> = []
    private _scroll: ScrollController

    constructor(model: typesBoxModel){
        super()
        this._model = model
        this._scroll = new ScrollController({onScroll: this.onScroll, itIsItem: true, horizontal: true})
        this.makeItems()
    }

    makeItems = () => {
        this._types = this._model.datas.map(ot => new OneType(ot))
    }

    onScroll = () => {
        let selectedItem:OneType|null = null
        const {w} = controllers().media
        this._types.filter(ot=>!ot.isEmpty).forEach(ot=>{
            ot.setSelected(false)
            if(this._scroll.pos+w*0.6 > ot.offsetLeft){
                selectedItem = ot
            }
        })
        if(selectedItem !== null){
            // @ts-ignore
            selectedItem.setSelected(true)
        }
    }

    orderNow = () => {
        const selected = this._types.find(ot=>ot.selected)
        controllers().modals.orderModal.show(selected?.image||"")
    }

    get items(){
        return this._types
    }

    get scroll(){
        return this._scroll
    }

}

export { TypesBox }