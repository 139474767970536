import { Model } from "../../Base/Model";

type menuItemModel = {
    name: string;
    title: string;
    link:string;
    onClick: (i:MenuItem)=>void
    selected?: boolean;
}

class MenuItem extends Model {
    private _model: menuItemModel
    private _selected: boolean;
    constructor(model: menuItemModel){
        super()
        this._model = model
        this._selected = this._model.selected||false
    }

    get name(){
        return this._model.name
    }
    get title(){
        return this._model.title
    }
    get link(){
        return this._model.link
    }
    onClick = () => {
        if(this._model.onClick){
            this._model.onClick(this)
        }
    }

    get selected(){
        return this._selected
    }
    setSelected = (bool: boolean) => {
        if(this._selected === bool){
            return
        }
        this._selected = bool
        this.updateMe()
    }
}

export { MenuItem }