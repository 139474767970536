import { Model } from "../../Base/Model";
import { Timeout } from "../../Base/Timeout";

type onePaggingModel = {
    number: number
    onPress?:(ind: number)=>void
    selected?: boolean
}

class OnePagging extends Model {
    private _model: onePaggingModel
    private _selected: boolean
    private _isRunning: boolean = false
    private _timeout: Timeout = new Timeout(300)

    constructor(model: onePaggingModel){
        super()
        this._model = model
        this._selected = model.selected || false
    }

    get selected(){
        return this._selected
    }
    get isRunning(){
        return this._isRunning
    }
    get number(){
        return this._model.number
    }
    setSelected = (bool: boolean) =>{
        if(this._selected === bool){
            return
        }
        this._selected = bool
        this.setTimeouted()
        this.updateMe()
    }
    setTimeouted = () => {
        if(this._selected){
            this._timeout.set(()=>this.setRunning(true, true))
        } else {
            this.setRunning(false)
        }
    }
    setRunning = (bool: boolean, update: boolean = false) => {
        if(this._isRunning === bool){
            return
        }
        this._isRunning = bool
        if(update){
            this.updateMe()
        }
    }
    mounted = () => {
        this.setTimeouted()
    }
    onPress = () => {
        if(this._model.onPress){
            this._model.onPress(this._model.number)
        }
    }
}

export { OnePagging }