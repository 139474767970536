import { PageModel } from "../../Base/PageModel"
import { EXICONS } from "../../Constants/icons"
import { Carousel } from "../../Models/Carousel/Carousel"
import { GalleryProjects } from "./Gallery/GalleryProjects"


class GalleyPage extends PageModel {
  private _carousel: Carousel = new Carousel({
    datas: [
      {
        title: "Inspiring Projects <c>Gallery</c>",
        description: "Explore our diverse portfolio of flooring and design projects. Get inspired by the stunning transformations and exceptional craftsmanship that define our work. Discover the possibilities for your own space.",
        image: EXICONS.gallery,
        selected: true
      },
    ]
  })

  private _gallery: GalleryProjects = new GalleryProjects({datas: [
    {
        name: "Garage in Broomall",
        images: [EXICONS.gi1,EXICONS.gi2,EXICONS.gi3,EXICONS.gi4,EXICONS.gi5]
    },
    {
      name: "Kitchen in Trenton",
      images: [EXICONS.gi4,EXICONS.gi5,EXICONS.gi6,EXICONS.gi2,EXICONS.gi1]
    },
    {
      name: "Kitchen in NYC",
      images: [EXICONS.gi9,EXICONS.gi7,EXICONS.gi8,EXICONS.gi3,EXICONS.gi1]
    },
  ]})

  pageMounted = () => {
    this._carousel.startRolling()
  };

  get carousel(){
    return this._carousel
  }
  get gallery(){
    return this._gallery
  }
}

export { GalleyPage };
