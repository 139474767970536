import { ViewItem } from "../../Base/ViewItem";
import { MenuController } from "../../Controllers/MenuController";
import { MenuItemView } from "./Menu/MenuItemView";


class MenuView extends ViewItem {

    get controller():MenuController{
        return this.props.controller
    }
    render(){
        const { items, visible } = this.controller
        return <div className={`menu ${visible ? 'menu-visible' : ''}`}>
            {items && items.map((e, i)=><MenuItemView key={i} ref={e.set} controller={e} />)}
        </div>
    }
}

export { MenuView }