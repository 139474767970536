import { Model } from "../Base/Model";

class LoaderController extends Model {
  private _visible: boolean;
  private _loaderRef: any;
  constructor() {
    super();
    this._visible = true;
    this._loaderRef = null;
  }

  get loader() {
    return this._loaderRef;
  }
  loaderRef = (ref: any) => {
    this._loaderRef = ref;
  };

  get visible() {
    return this._visible;
  }

  setVisible = (visible: boolean) => {
    if (this._visible === visible) {
      return;
    }
    this._visible = visible;
    this.updateMe();
  };

  show = () => {
    this.setVisible(true);
  };
  hide = () => {
    this.setVisible(false)
  };
  toggle = () => {
    this.setVisible(!this._visible);
  };

}

export { LoaderController };
