type screenType = {
  w: number;
  h: number;
};

class MediaController {
  private _screen: screenType;
  constructor() {
    this._screen = {
      w: 0,
      h: 0,
    };
  }

  get screen() {
    return this._screen;
  }

  reinit = () => {
    this._screen = {
        w: window.innerWidth,
        h: window.innerHeight
    }
    this.reinitFunctions()
    // console.log('reinit', this._screen)
  }

  reinitFunctions = () => {

  }

  get w(): number {
    return this._screen?.w;
  }
  get h(): number {
    return this._screen?.h;
  }
}

export { MediaController };
