import { Model } from "../../../Base/Model";
import { OneProject, oneProjectModel } from "./OneProject";

type galleryModel = {
    datas: Array<oneProjectModel>
}

class GalleryProjects extends Model {
    private _items: Array<OneProject> = []
    private _model: galleryModel
    constructor(model: galleryModel){
        super()
        this._model = model
        this.makeItems()
    }
    makeItems = () => {
        this._items = this._model.datas.map(op => new OneProject(op))
    }
    
    get items(){
        return this._items
    }
}

export { GalleryProjects }