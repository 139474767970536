import { ViewItem } from "../../../Base/ViewItem";
import { GalleryProjects } from "../../../Controllers/Pages/Gallery/GalleryProjects";
import { OneProjectView } from "./OneProjectView";


class GalleryProjectsView extends ViewItem {
    get controller():GalleryProjects{
        return this.props.controller
    }
    render(){
        const { items } = this.controller
        return <div className="gallery">
            <div className="gallery-view">
                {items && items.map((op,i) => <OneProjectView key={i} ref={op.set} controller={op}/>)}
            </div>
        </div>
    }
}

export { GalleryProjectsView }