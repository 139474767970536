import { PageModel } from "../../Base/PageModel"
import { EXICONS } from "../../Constants/icons"
import { Carousel } from "../../Models/Carousel/Carousel"
import { Inspiration } from "../../Models/Inspiration/Inspiration"
import { TypesBox } from "../../Models/TypesBox/TypesBox"
import { VideosBox } from "../../Models/VideosBox/VideosBox"
import { navigator } from "../Navigation"


class ContertopsPage extends PageModel {
  private _carousel: Carousel = new Carousel({
    datas: [
      {
        title: "<c>Countertops</c> on all tastes",
        description: "Discover a world of countertop options tailored to suit every palate and preference. Our extensive selection ensures that you'll find the perfect countertop solution that matches your unique style and taste.",
        image: EXICONS.contertops,
        selected: true
      },
      {
        title: "<c>Countertops</c> on all tastes",
        description: "Discover a world of countertop options tailored to suit every palate and preference. Our extensive selection ensures that you'll find the perfect countertop solution that matches your unique style and taste.",
        image: EXICONS.contertops,
      },
    ]
  })
  private _videos: VideosBox = new VideosBox({
    datas: [
      {
        title: "Countertops great decision for every kitchen",
        previewImage: EXICONS.convideo,
        videoSource: " ",
        selected: true
      },
      {
        title: "Countertops great decision for every kitchen",
        previewImage: EXICONS.convideo,
        videoSource: " "
      },
    ]
  })

  private _types: TypesBox = new TypesBox({
    datas: [
      {isEmpty: true, image:""},
      {image: EXICONS.contype1, selected: true},
      {image: EXICONS.contype2},
      {image: EXICONS.contype3},
      {image: EXICONS.contype4},
      {isEmpty: true, image:""},
    ]
  })

  private _inspiration: Inspiration = new Inspiration({
    datas: [
      {image: EXICONS.coni1},
      {image: EXICONS.coni2},
      {image: EXICONS.coni3,selected: true},
      {image: EXICONS.coni4},
      {image: EXICONS.coni5},
      {image: EXICONS.coni1},
      {image: EXICONS.coni3},
      {image: EXICONS.coni4},
      {image: EXICONS.coni2},
      {image: EXICONS.coni5},
      {image: EXICONS.coni3},
      {image: EXICONS.coni1},
    ]
  })

  pageMounted = () => {
    this._carousel.startRolling()
  };
  
  playCurrentVideo = () => {
    this._videos.getSelected?.playButttonClicked()
  }
  showGallery = () => {
    navigator().navigate('/portfolio')
  }

  get carousel(){
    return this._carousel
  }
  
  get videosBox(){
    return this._videos
  }

  get types(){
    return this._types
  }
  get inspiration(){
    return this._inspiration
  }
}

export { ContertopsPage };
