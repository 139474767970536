import { ViewItem } from "../../../Base/ViewItem";
import { CarouselItem } from "../../../Models/Carousel/CarouselItem";
import { ImageView } from "../ImageView";
import { transformString } from "../TextTransform";


class CarouselItemView extends ViewItem {

    get controller():CarouselItem{
        return this.props.controller
    }
    render(){
        const { title, description, image, setPosRef } = this.controller
        return <div className="carousel-item" ref={setPosRef}>
            <div className="carousel-img-container">
                <div className="black-back"></div>
                <div className="carousel-img-box">
                    <ImageView 
                        className="carousel-img"
                        source={image}
                    />
                </div>
            </div>
            <div className="carousel-content">
                <span className="carousel-title">{transformString(title)}</span>
                <span className="carousel-description">{description}</span>
            </div>
        </div>
    }
}

export { CarouselItemView }