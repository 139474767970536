import { PageController } from '../PageController';
import { CompanyPage } from '../Pages/CompanyPage';
import { ContertopsPage } from '../Pages/ContertopsPage';
import { GalleyPage } from '../Pages/GalleryPage';
import { HomePage } from '../Pages/HomeStack/HomePage';

class HomeStackModel extends PageController {
  private readonly _home: HomePage;
  private readonly _contertops: ContertopsPage;
  private readonly _company: CompanyPage;
  private readonly _gallery: GalleyPage;

  constructor() {
    super();
    this._home = new HomePage('Home');
    this._contertops = new ContertopsPage('Contertops');
    this._company = new CompanyPage("Company")
    this._gallery = new GalleyPage("Gallery")
    this.pages = [this._home, this._contertops, this._company, this._gallery];
  }

  get home() {
    return this._home;
  }

  get contertops(){
    return this._contertops
  }
  get company(){
    return this._company
  }
  
  get gallery(){
    return this._gallery
  }
}

export { HomeStackModel };
