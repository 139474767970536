import { Model } from "../Base/Model";
import { controllers } from "./Controllers";
import { MenuItem } from "./Menu/MenuItem";
import { navigator } from "./Navigation";


class MenuController extends Model {

    private _visible: boolean = true
    private _items: Array<MenuItem> = []

    constructor(){
        super()
        this._visible = window.innerWidth > 800
        this._items = [
            new MenuItem({
                name: "home",
                title: "Epoxy Flooring",
                link: "/",
                onClick: this.onClickItem,
                selected: true
            }),
            new MenuItem({
                name: "countertops",
                title: "Countertops",
                link: "/countertops",
                onClick: this.onClickItem,
            }),
            new MenuItem({
                name: "company",
                title: "Company",
                link: "/company",
                onClick: this.onClickItem,
            }),
            new MenuItem({
                name: "portfolio",
                title: "Portfolio",
                link: "/portfolio",
                onClick: this.onClickItem,
            }),
            // new MenuItem({
            //     name: "prices",
            //     title: "Prices",
            //     link: "/prices",
            //     onClick: this.onClickItem,
            // }),
        ]
    }

    onClickItem = (e: MenuItem) => {
        this._items.forEach(it => {
            it.setSelected(it.name === e.name)
        })
        navigator().navigate(e.link)
        this.setVisible(false)
    }
    selectItem = (e:MenuItem) => {
        this._items.forEach(it => {
            it.setSelected(it.name === e.name)
        })
    }

    get items(){
        return this._items
    }

    get visible(){
        return this._visible
    }
    setVisible = (bool: boolean) => {
        if(this._visible === bool){
            return
        }
        this._visible = bool
        this.updateMe()
    }
    toggle = () => {
        this.setVisible(!this._visible)
    }

    chooseCurrentPage = () => {
        const pathname = window.location.pathname
        const menuItem = this._items.find(mi => mi.link === pathname)
        if(menuItem){
            this.selectItem(menuItem)
        }
    }
}

export { MenuController }