import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { controllers } from '../Controllers/Controllers'
import { navigator } from '../Controllers/Navigation'
import { Core } from './Core'
import { HomePageView } from '../Views/Pages/HomePageView'
import { ContertopsPageView } from '../Views/Pages/CountertopsPageView'
import { CompanyPageView } from '../Views/Pages/CompanyPageView'
import { GalleryPageView } from '../Views/Pages/GalleryPageView'

class MainNavigation extends React.Component {

    render(){
        const { home, contertops, company, gallery } = controllers().main
        return <Router
            ref={(ref:any)=>{
                navigator().set(ref)
            }}
        >
            <Core>
                <Switch>
                    <Route path="/portfolio">
                        <GalleryPageView controller={gallery}/>
                    </Route>
                    <Route path="/company">
                        <CompanyPageView controller={company}/>
                    </Route>
                    <Route path="/countertops">
                        <ContertopsPageView controller={contertops}/>
                    </Route>
                    <Route path="/">
                        <HomePageView controller={home}/>
                    </Route>
                </Switch>
            </Core>
        </Router>
    }
}

export { MainNavigation }