import { Model } from "../../Base/Model";

export type oneTypeModel = {
    image: string;
    isEmpty?: boolean
    selected?: boolean;
}

class OneType extends Model {
    private _model: oneTypeModel
    private _selected: boolean;
    private _domRef: any;
    private _offsetLeft: number = 0;

    constructor(model: oneTypeModel){
        super()
        this._model = model
        this._selected = model.selected || false
    }

    get image(){
        return this._model.image
    }

    get isEmpty(){
        return this._model.isEmpty
    }

    get selected(){
        return this._selected
    }

    setSelected = (bool: boolean) => {
        if(this._selected === bool){
            return
        }
        this._selected = bool
        this.updateMe()
    }
    setDomRef = (e:any) => {
        this._domRef = e
        this._offsetLeft = e && e.offsetLeft ? e.offsetLeft : 0
    }

    get offsetLeft(){
        return this._offsetLeft
    }
    
}

export { OneType }