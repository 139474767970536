import React from "react"
import { Button } from "../../../Components/Button"
import { navigator } from "../../../../Controllers/Navigation";


type oneSolutionProps = {
    title: string;
    description: string;
    link?: string;
}
class OneSolutionView extends React.Component<oneSolutionProps> {
    pressButton = () => {
        if(this.props.link){
            navigator().navigate(this.props.link)
        }
    }
    render(){
        const { title, description, link } = this.props
        return <div className="one-solution">
            <div className="one-solution-card">
                <div className="one-solution-top">
                    <div className="solution-checker">
                        
                    </div>
                </div>
                <div className="one-solution-content">
                    <span className="one-solution-title">{title}</span>
                    <span className="one-solution-description">{description}</span>
                    {link && <Button
                        onClick={this.pressButton}
                        title="Learn more"
                        className="learn-more-btn"
                    />}
                </div>
            </div>
        </div>
    }
}

export { OneSolutionView }