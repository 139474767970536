import { ReactNode } from "react";
import { ViewItem } from "../../../Base/ViewItem";
import { Carousel } from "../../../Models/Carousel/Carousel";
import { CarouselItemView } from "./CarouselItemView";
import { CarouselPagin } from "../CarouselPagin/CarouselPagin";

class CarouselView extends ViewItem {

    get controller():Carousel{
        return this.props.controller
    }

    componentWillUnmount(): void {
        this.controller.stop()
    }


    render() {
        const { items, pagging, setScrollRef } = this.controller

        return <div className="carousel">
            <div className="carousel-box no-scroll" ref={setScrollRef}>
                {items && items.map((ci, i)=> <CarouselItemView key={i} ref={ci.set} controller={ci}/>)}
            </div>
            <div className="carousel-pagin-box">
                <CarouselPagin ref={pagging.set} controller={pagging} />
            </div>
        </div>
    }
}

export { CarouselView }