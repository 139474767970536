import React from "react";
import { transformString } from "../../Components/TextTransform";


type oneCompetetive = {
    title: string;
    description: string;
}

class OneCompetetiveView extends React.Component<oneCompetetive> {

    render(){
        const { title, description } = this.props 
        return <div className="one-comp">
                <span className="one-comp-title">{transformString(title)}</span>
                <span className="one-comp-description">{description}</span>
        </div>
    }
}

export { OneCompetetiveView }