import { PageModel } from "../../../Base/PageModel";
import { EXICONS } from "../../../Constants/icons";
import { Carousel } from "../../../Models/Carousel/Carousel";
import { Inspiration } from "../../../Models/Inspiration/Inspiration";
import { TypesBox } from "../../../Models/TypesBox/TypesBox";
import { VideosBox } from "../../../Models/VideosBox/VideosBox";
import { navigator } from "../../Navigation";
import { CompetetiveBox } from "./Home/CompetetiveBox";

class HomePage extends PageModel {
  private _carousel: Carousel = new Carousel({
    datas: [
      {
        title: "Experience the Artistry of <c>Luxury Epoxy Flooring</c> and Venetian Walls",
        description: "Discover the cutting-edge innovation and superior performance of our new generation epoxy flooring solutions. Elevate your space with unmatched durability, style, and functionality for a flooring experience like never before.",
        image: EXICONS.mainbg,
        selected: true
      },
      {
        title: "<c>New generation</c> of Epoxy Flooring",
        description: "Discover the cutting-edge innovation and superior performance of our new generation epoxy flooring solutions. Elevate your space with unmatched durability, style, and functionality for a flooring experience like never before.",
        image: EXICONS.mainbg
      },
    ]
  })
  private _videos: VideosBox = new VideosBox({
    datas: [
      {
        title: "Epoxy Flooring it’s easy to maintance",
        previewImage: EXICONS.vo1,
        videoSource: " ",
        selected: true
      },
      {
        title: "Epoxy Flooring it’s easy to maintance",
        previewImage: EXICONS.vo1,
        videoSource: " "
      },
      {
        title: "Epoxy Flooring it’s easy to maintance",
        previewImage: EXICONS.vo1,
        videoSource: " "
      },
    ]
  })

  private _types: TypesBox = new TypesBox({
    datas: [
      {isEmpty: true, image:""},
      {image: EXICONS.ot1, selected: true},
      {image: EXICONS.ot2},
      {image: EXICONS.ot3},
      {image: EXICONS.ot4},
      {isEmpty: true, image:""},
    ]
  })

  private _inspiration: Inspiration = new Inspiration({
    datas: [
      {image: EXICONS.ii1},
      {image: EXICONS.ii2},
      {image: EXICONS.ii3,selected: true},
      {image: EXICONS.ii4},
      {image: EXICONS.ii5},
      {image: EXICONS.ii1},
      {image: EXICONS.ii3},
      {image: EXICONS.ii4},
      {image: EXICONS.ii2},
      {image: EXICONS.ii5},
      {image: EXICONS.ii3},
      {image: EXICONS.ii1},
    ]
  })

  private _competetive: CompetetiveBox = new CompetetiveBox()

  pageMounted = () => {
    this._carousel.startRolling()
  };
  
  playCurrentVideo = () => {
    this._videos.getSelected?.playButttonClicked()
  }
  showGallery = () => {
    navigator().navigate('/portfolio')
  }

  get carousel(){
    return this._carousel
  }
  
  get videosBox(){
    return this._videos
  }

  get types(){
    return this._types
  }
  get inspiration(){
    return this._inspiration
  }

  get competetive(){
    return this._competetive
  }
}

export { HomePage };
