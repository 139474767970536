import { PageView } from "../../Base/PageView";
import { GalleyPage } from "../../Controllers/Pages/GalleryPage";
import { CarouselView } from "../Components/CarouselVIew/CarouselView";
import { TitleView } from "../Components/TitleView";
import { GalleryProjectsView } from "./Gallery/GalleryProjectsView";

class GalleryPageView extends PageView {

    get controller(): GalleyPage {
        return this.props.controller
    }
    render(){
        const { carousel, gallery} = this.controller
        return <div className="page">
            <CarouselView ref={carousel.set} controller={carousel} />
            <TitleView text="Insights and showcase"/>
            <GalleryProjectsView 
                ref={gallery.set}
                controller={gallery}
            />
        </div>
    }
}

export { GalleryPageView }