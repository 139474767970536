import { Model } from "../Base/Model";
import { MenuController } from "./MenuController";


class AppHeaderController extends Model {

    private _menu: MenuController = new MenuController()

    get menu(){
        return this._menu
    }
}

export { AppHeaderController }