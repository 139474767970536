import { Modal } from "../../Models/Modal";
import { ScrollController } from "../../Models/ScrollController";
import { controllers } from "../Controllers";


class PhotosModal extends Modal {
    private _photos: Array<string> = []
    private _scroll: ScrollController = new ScrollController({horizontal: true, itIsItem: true})

    show = (photos: Array<string>, selectedIndex: number) => {
        this._photos = [photos[selectedIndex],...photos.filter(op=>op!==photos[selectedIndex])]
        this.setVisible(true)
        // setTimeout(()=>this._scroll.scroll(controllers().media.w*selectedIndex), 100)
    }

    hide = () => {
        this._photos = []
        this.setVisible(false)
    }

    get photos(){
        return this._photos
    }
    get scroll(){
        return this._scroll
    }
}

export { PhotosModal }