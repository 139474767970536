import { ViewItem } from "../../../Base/ViewItem";
import { ICONS } from "../../../Constants/icons";
import { ImageView } from "../../Components/ImageView";
import { OneMissionView } from "./OneMissionView";


class MissionBoxView extends ViewItem {

    get controller(){
        return this.props.controller
    }
    render(){
        return <div className="mission">
            <div className="mission-view">
                <div className="mission-height">
                    <div className="image-side">
                        <ImageView 
                            className="mission-image"
                            source={ICONS.vision}
                        />
                    </div>
                    <OneMissionView 
                        title="Our <c>Vision</c>"
                        description="We are dedicated to providing top-notch flooring and design services that enhance the beauty and functionality of every space we touch."
                    />
                </div>
                <div className="mission-height">
                    <div className="mission-left"/>
                    <div className="mission-right">
                        <OneMissionView 
                            title="Our <c>Promise</c>"
                            description="Is to seamlessly blend the quality and durability of our products and services with your unique vision, crafting perfection until your satisfaction is achieved."
                        />
                    </div>
                </div>
                <div className="mission-height">
                    <OneMissionView 
                        title="Our <c>Focus</c>"
                        description="We strive to be the go-to source for cutting-edge flooring and design, guided by a commitment to craftsmanship, creativity, and customer satisfaction."
                    />
                    <div className="mission-left"/>
                    <div className="image-side mission-focus-image">
                        <ImageView 
                            className="mission-image"
                            source={ICONS.focus}
                        />
                    </div>
                </div>
                <div className="mission-height">
                    <div className="mission-left">

                    </div>
                    <div className="mission-right">
                        <OneMissionView 
                            title="Our <c>Purpose</c>"
                            description={`"Elevating Environments, Inspiring Excellence." Our Mission is to not only deliver unparalleled customer service but also to become the premier epoxy flooring business in the entire Northeast and beyond, setting new standards for innovation, quality, and satisfaction. "`}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export { MissionBoxView }