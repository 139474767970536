import React from 'react'
import { controllers } from '../Controllers/Controllers';

class AppStateController extends React.Component {
    mounted: boolean = false

    componentDidMount(){
        if(this.mounted){
            return
        }
        this.mounted = true
        window.addEventListener('resize', controllers().media.reinit);
        controllers().media.reinit()
        controllers().menu.chooseCurrentPage()
        // AppStateController.restoreData()
    }

    componentWillUnmount(){
        // this.mounted = false
        if(!this.mounted){
            return
        }
        // this.mounted = false
        window.removeEventListener('resize', controllers().media.reinit);
    }

    static async restoreData(){
        await controllers().auth.restoreMe()
        await controllers().auth.checkToken();
        if(controllers().auth.isLogin){
            await controllers().auth.getUserInfo();
        } else {
            controllers().loader.hide()
        }
    }

    render(){
        return null
    }
}

export { AppStateController }