import { AskQuestion } from "./Modals/AskQuestion";
import { CallMeBack } from "./Modals/CallMeBack";
import { GetConsultation } from "./Modals/GetConsultation";
import { OrderModal } from "./Modals/OrderModal";
import { PhotosModal } from "./Modals/PhotosModal";

class Modals {

    private _photos: PhotosModal = new PhotosModal()
    private _orderModal: OrderModal = new OrderModal()
    private _getConsultation: GetConsultation = new GetConsultation()
    private _askQuestion: AskQuestion = new AskQuestion()
    private _callMeBack: CallMeBack = new CallMeBack()

    get photos(){
        return this._photos
    }
    
    get orderModal(){
        return this._orderModal
    }
    
    get getConsultation(){
        return this._getConsultation
    }

    get askQuestion(){
        return this._askQuestion
    }

    get callMeBack(){
        return this._callMeBack
    }
}

export { Modals };
