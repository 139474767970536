import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS } from "../../Constants/icons";
import { CallMeBack } from "../../Controllers/Modals/CallMeBack";
import { GetConsultation } from "../../Controllers/Modals/GetConsultation";
import { OrderModal } from "../../Controllers/Modals/OrderModal";
import { Button } from "../Components/Button";
import { ImageView } from "../Components/ImageView";
import { ModalSuit } from "./ModalSuit";


class CallMeBackModal extends ViewItem {

    get controller(): CallMeBack {
        return this.props.controller
    }

    render(){
        const { hide, visible, title } = this.controller
        return <ModalSuit title={title} customClass="small-modal" hide={hide} visible={visible}>
                <span className="modal-title">Call me back</span>
                <span className="modal-description">Alright, just give us a moment. We will be with you shortly!</span>
                <div className="modal-flex-1"/>
                <div className="modal-input-box">
                    <input 
                        name="phone"
                        placeholder="Enter your phone"
                        className="modal-input"
                    />
                </div>
                <div className="modal-submit">
                    <Button 
                        title="Submit"
                        onClick={()=>{}}
                        isSubmit
                        className="submit-button"
                        icon={BUTTONICONS.right}
                    />
                </div>
            </ModalSuit>
    }
}

export { CallMeBackModal }