import { ViewItem } from "../../../Base/ViewItem";
import { ICONS } from "../../../Constants/icons";
import { OneVideo } from "../../../Models/VideosBox/OneVideo";
import { ImageView } from "../ImageView";


class OneVideoView extends ViewItem {

    get controller(): OneVideo{
        return this.props.controller
    }
    render(){
        const { title, previewImage, videoSource, isVideo, setPosRef, setVideoRef, playButttonClicked } = this.controller
        return <div className="one-video" ref={setPosRef}>
            <div className={`one-video-box ${isVideo ? "video-active" : ''}`}>
                <span className="video-absolute-title">{title}</span>
                {isVideo ? <div className="moving-video-box">
                    <video ref={setVideoRef} className="moving-video" controls>
                        <source src={videoSource} type="video/mp4"/>
                    </video>
                </div> : <div className="video-preview-image-box">
                    <ImageView
                        className="video-preview-image"
                        source={previewImage}
                    />
                </div>}
                <div className="video-absolute-btn">
                    <div className="video-btn-cont" onClick={playButttonClicked}>
                        <ImageView 
                            source={ICONS.play}
                            className="video-btn-img"
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export { OneVideoView }