import { ViewItem } from "../../../Base/ViewItem";
import { controllers } from "../../../Controllers/Controllers";
import { Inspiration } from "../../../Models/Inspiration/Inspiration";
import { OneInspirationView } from "./OneInspirationView";


class InspirationView extends ViewItem {

    get controller(): Inspiration{
        return this.props.controller
    }
    render(){
        const { items, scroll } = this.controller
        return <div className="inspiration">
            <div className="inspiration-view no-scroll" onScroll={scroll.onScroll}>
                {items && items.map((oi, i)=><OneInspirationView key={i} ref={oi.set} controller={oi}/>)}
            </div>
        </div>
    }
}

export { InspirationView }