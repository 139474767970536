import { ViewItem } from "../../../Base/ViewItem";
import { EXICONS } from "../../../Constants/icons";
import { CompetetiveBox } from "../../../Controllers/Pages/HomeStack/Home/CompetetiveBox";
import { ImageView } from "../../Components/ImageView";
import { OneCompetetiveView } from "./OneCompetetiveView";

class CompetetiveBoxView extends ViewItem {

    get controller():CompetetiveBox{
        return this.props.controller
    }
    render(){
        return <div className="competetive">
            <div className="competetive-view">
                <div className="competetive-height">
                    <div className="left-comp">
                        <OneCompetetiveView 
                            title="Licensed and <c>insured</c>"
                            description="We take great pride in offering insurance coverage that goes above and beyond our competitors, ensuring that our customers can rest easy knowing their homes or business facilities are protected when we undertake projects."
                        />
                    </div>
                    <div className="right-comp"/>
                </div>
                <div className="competetive-height">
                    <div className="left-comp">

                    </div>
                    <div className="right-comp">
                        <OneCompetetiveView 
                            title="<c>Quality</c> and durability"
                            description="Epoxy floors built by us are made to last. They resist stains, abrasion, and chemicals, ensuring a long-lasting, low-maintenance surface that stands up to heavy use. 
                            By using the best products on the market we achieving top tier quality. We provide sleek, customizable finish, exceptional adhesion, and a resilient surface, making them a premium choice for both residential and commercial spaces."
                        />
                    </div>
                </div>
                <div className="competetive-height four-comp-box">
                    <div className="competetive-50">
                        <ImageView 
                            className="one-o-four-comp-photo"
                            source={EXICONS.ii5}
                        />
                    </div>
                    <div className="competetive-50">
                        <OneCompetetiveView 
                            title="<c>Versatile</c> Flooring Options"
                            description="Explore our extensive selection of flooring options, featuring top-quality materials and a vast array of designs to bring your vision to life, ensuring enduring, standout results. Count on our experienced designers to guide you in choosing the ideal color and finish for your floors, whether you desire a glossy or matte finish."
                        />
                    </div>
                    <div className="competetive-50">
                        <ImageView 
                            className="one-o-four-comp-photo"
                            source={EXICONS.ii3}
                        />
                    </div>
                    <div className="competetive-50">
                        <ImageView 
                            className="one-o-four-comp-photo"
                            source={EXICONS.ii4}
                        />
                    </div>
                </div>
                <div className="competetive-height">
                    <div className="left-comp">
                        <OneCompetetiveView 
                            title="Flexible and <c>Customer-Centric</c>"
                            description="At Top Dog Coatings we put you at the center of everything we do. Our flexible approach means we tailor our solutions to match your unique requirements. Whether it's customizing your epoxy flooring design, adjusting schedules, or accommodating special requests, we're here to make it happen. Your satisfaction and convenience are our top priorities."
                        />
                    </div>
                    <div className="right-comp"/>
                </div>
            </div>
        </div>
    }
}

export { CompetetiveBoxView }