import { Model } from "../../Base/Model";


export type oneVideoModel = {
    title: string;
    previewImage: string;
    videoSource: string;
    selected?: boolean
}

class OneVideo extends Model {
    private _model: oneVideoModel
    private _selected:boolean
    private _posRef: any
    private _offsetLeft: number = 0
    private _isVideo: boolean = false
    private _videoRef: any 

    constructor(model: oneVideoModel){
        super()
        this._model = model
        this._selected = model.selected || false
    }

    get title(){
        return this._model.title
    }
    get previewImage(){
        return this._model.previewImage
    }
    get videoSource(){
        return this._model.videoSource
    }

    get selected(){
        return this._selected
    }

    get isVideo(){
        return this._isVideo
    }
    
    setSelected = (bool: boolean) => {
        if(this._selected === bool){
            return
        }
        this._selected = bool
        if(!bool){
            this.setUnplayed()
        }
        this.updateMe()
    }
    setPosRef = (e: any) => {
        this._posRef = e
        this._offsetLeft = this._posRef && this._posRef.offsetLeft ? this._posRef.offsetLeft : 0
    }
    setVideoRef = (e: any) => {
        this._videoRef = e
    }

    setUnplayed = () => {
        // this._isVideo = false
        if(this._videoRef){
            this._videoRef.pause()
        }
    }

    playButttonClicked = () => {
        this._isVideo = true
        this.updateMe()
    }

    get offsetLeft() {
        return this._offsetLeft
    }
}

export { OneVideo }