import { AlertController } from './AlertController';
import { AppFooterController } from './AppFooterController';
import { AppHeaderController } from './AppHeaderController';
import { AuthController } from './AuthController';
import { LanguageController } from './LanguageController';
import { LoaderController } from './LoaderController';
import { MediaController } from './MediaController';
import { Modals } from './Modals';
import { ResponseLoader } from './ResponseLoader';
import { ScrollController } from './ScrollController';
import { HomeStackModel } from './Stacks/HomeStackModel';

class ControllersImpl {
  // private readonly _firebaseController: FirebaseController;
  private readonly _media: MediaController;
  private readonly _loader: LoaderController;
  private readonly _alert: AlertController;
  private readonly _auth: AuthController;
  private readonly _home: HomeStackModel;
  private readonly _modals: Modals;
  private readonly _scrollController: ScrollController;
  // private readonly _permission: PermissionController;
  private readonly _responseLoader: ResponseLoader;
  private readonly _language: LanguageController;
  private readonly _appHeader: AppHeaderController
  private readonly _appFooter: AppFooterController

  constructor() {
    // this._firebaseController = new FirebaseController();
    this._media = new MediaController();
    this._loader = new LoaderController();
    this._alert = new AlertController();
    this._responseLoader = new ResponseLoader();
    this._auth = new AuthController();
    this._modals = new Modals();
    // this._permission = new PermissionController();
    this._language = new LanguageController();
    this._home = new HomeStackModel();
    this._scrollController = new ScrollController()
    this._appHeader = new AppHeaderController()
    this._appFooter = new AppFooterController()
  }

  get media() {
    return this._media;
  }

  get loader() {
    return this._loader;
  }

  get responseLoader() {
    return this._responseLoader;
  }

  get alert() {
    return this._alert;
  }

  get auth() {
    return this._auth;
  }

  get modals() {
    return this._modals;
  }

  get language() {
    return this._language;
  }

  get main() {
    return this._home;
  }

  get scroll(){
    return this._scrollController
  }

  get appHeader(){
    return this._appHeader
  }
  get menu(){
    return this._appHeader.menu
  }

  get footer(){
    return this._appFooter
  }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.controllers =
  // @ts-ignore
  global.__app__.controllers || new ControllersImpl();

export function controllers(): ControllersImpl {
  // @ts-ignore
  return global.__app__.controllers;
}
