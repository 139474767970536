import React from "react";
import { ImageView } from "../../../Components/ImageView";


type oneContactProps = { 
    ico: string;
    description: string
    title: string;
    link: string;
}

class OneContactView extends React.Component<oneContactProps> {

    render(){
        const { ico, description, title, link } = this.props
        return <div className="one-info">
        <div className="one-info-ico-box">
            <ImageView 
                source={ico}
                className="one-info-ico"
            />
        </div>
        <div className="one-info-text">
            <span className="one-info-description">{description}</span>
            <a className="one-info-link" href={link}>
                {title}
            </a>
        </div>
    </div>
    }
}

export { OneContactView }