import { Model } from "../Base/Model";
import { controllers } from "../Controllers/Controllers";

class PageScroll extends Model {
  private _maxHeight: number;
  private _scrollRef: any;
  private _isKeyboard: boolean;
  constructor() {
    super();
    this._maxHeight = 0;
    this._scrollRef = null;
    this._isKeyboard = false;
  }

  get scrollRef() {
    return this._scrollRef;
  }
  setScrollRef = (ref: any) => {
    this._scrollRef = ref;
  };
  scroll = (y: number = 0, x: number = 0) => {
    try {
      this._scrollRef.scroll(x, y);
    } catch (e) {}
  };
  // scrollToEnd = () =>

  get maxHeight() {
    return this._maxHeight;
  }

  setMaxHeight = (maxHeight: number) => {
    if (this._maxHeight === maxHeight) {
      return;
    }
    this._maxHeight = maxHeight;
    this.updateMe();
  };

  setIsKeyboard = (bool: boolean) => {
    if (this._isKeyboard === bool) {
      return;
    }
    this._isKeyboard = bool;
  };

  get isKeyboard() {
    return this._isKeyboard;
  }

  toNormalSize = () => {
    this.setMaxHeight(controllers().media.screen.h);
  };
}

export { PageScroll };
