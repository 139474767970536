export class AppSettings {
  private static _baseUrl = 'https://bober.tv';
  private static _endpoint = 'https://demo.api.arkyn-tech.com/api';
  private static _testendpoint = 'https://demo.api.arkyn-tech.com/api';
  private static _appname = 'Bober TV';
  private static _devMode = true;

  public static get endpoint() {
    return this._devMode ? this._testendpoint : this._endpoint;
  }

  public static get appname() {
    return this._appname;
  }

  public static get devMode() {
    return this._devMode;
  }

  public static get baseUrl() {
    return this._baseUrl;
  }
}

export type langs = 'en' | 'hu';

export interface langRowType {
  [key: string]: string;
}
