import React from "react";
import { OneLeaderView } from "./OneLeaderView";

class LeadershipBoxView extends React.Component {

    render(){
        return <div className="leadership">
            <div className="leadership-view">
                <OneLeaderView 
                    name="Serhii Vulshinskiy"
                    title="CEO"
                />
                <OneLeaderView 
                    name="Oleksandr Babii"
                    title="Construction Manager"
                />
                <OneLeaderView 
                    name="Akbar Muhidinov"
                    title="Crew Lead"
                />
            </div>
        </div>
    }
}

export { LeadershipBoxView }