import { ViewItem } from "../../../Base/ViewItem";
import { CarouselPagin } from "../CarouselPagin/CarouselPagin";
import { OneVideoView } from "./OneVideoView";
import { VideosBox } from "../../../Models/VideosBox/VideosBox";
import { JustPagging } from "./JustPagging";

class VideosBoxView extends ViewItem {

    get controller():VideosBox{
        return this.props.controller
    }

    render() {
        const { items, setScrollRef, setPaggingRef, scrollToIndex} = this.controller
        return <div className="carousel">
            <div className="carousel-box no-scroll" ref={setScrollRef}>
                {items && items.map((ci, i)=> <OneVideoView key={i} ref={ci.set} controller={ci}/>)}
            </div>
            <div className="video-pagin-box">
                {/* <CarouselPagin ref={pagging.set} controller={pagging} /> */}
                <JustPagging 
                    ref={setPaggingRef}
                    itemsLength={items.length}
                    onPress={scrollToIndex}
                />
            </div>
        </div>
    }
}

export { VideosBoxView }