import React from "react";

type titleProps = {
    text: string;
    children?:any
}

class TitleView extends React.Component<titleProps> {

    render() {
        const { text, children } = this.props
        return <div className="title-view">
            <span className="title-text">{text}</span>
            <div className="right-title">
                {children}
            </div>
        </div>
    }
}

export { TitleView }