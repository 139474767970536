import { Model } from "../../Base/Model";
import { CarouselPagging } from "../Carousel/CarouselPagging";
import { OneVideo, oneVideoModel } from "./OneVideo";

type videosBoxModel = {
    datas: Array<oneVideoModel>
}

class VideosBox extends Model {
    private _model: videosBoxModel
    private _items: Array<OneVideo> = []
    private _scrollRef: any
    private _paggingRef: any
    // private _pagging: CarouselPagging
    constructor(model:videosBoxModel){
        super()
        this._model = model
        // this._pagging = new CarouselPagging({onPress: this.scrollToIndex})
        this.makeItems()
    }

    scrollToNext = () => {
        const index = this.getSelectedIndex
        const current = this._items[index]
        const next = this._items[index+1]||this._items[0]
        if(current){
            current.setSelected(false)
        }
        next.setSelected(true)
        this.setCurrentSelected()
        this.scrollToCurrent()
    }

    setCurrentSelected = () => {
        this._paggingRef.setCurrentIndex(this.getSelectedIndex)
        // this._pagging.setCurrentSelected(this.getSelectedIndex)
    }

    scrollToIndex = (index: number) => {
        if(this.getSelectedIndex === index){
            return
        }
        const current = this.getSelected
        if(current){
            current.setSelected(false)
        }
        const next = this._items[index]||this._items[0]
        next.setSelected(true)
        this.setCurrentSelected()
        this.scrollToCurrent()
    }

    makeItems = () => {
        this._items = this._model.datas.map(ov=>new OneVideo(ov))
        // this._pagging.makeLength(this._items.length)
        // this.updateMe()
    }

    setScrollRef = (e:any) => {
        this._scrollRef = e
    }
    setPaggingRef = (e: any) =>{ 
        this._paggingRef = e
    }

    scrollToCurrent = () => {
        const currentItem = this.getSelected
        if(currentItem && this._scrollRef){
            this._scrollRef.scroll({
                left: currentItem.offsetLeft,
                behavior: 'smooth', // Use 'auto' for instant scrolling
            });
        }
    }

    get getSelectedIndex(){
        return this._items.findIndex(ci=>ci.selected)
    }
    get getSelected(){
        return this._items.find(ci=>ci.selected)
    }
    get items(){
        return this._items
    }
    // get pagging(){
    //     return this._pagging
    // }
}

export { VideosBox }