import { ViewItem } from "../../../Base/ViewItem";
import { BUTTONICONS } from "../../../Constants/icons";
import { TypesBox } from "../../../Models/TypesBox/TypesBox";
import { Button } from "../Button";
import { OneTypeView } from "./OneTypeView";


class TypesBoxView extends ViewItem {

    get controller(): TypesBox {
        return this.props.controller
    }
    render(){
        const { items, scroll, orderNow } = this.controller
        return <div className="types">
            <div className="types-view no-scroll" onScroll={scroll.onScroll}>
                {items && items.map((ot, i)=><OneTypeView key={i} ref={ot.set} controller={ot} />)}
            </div>
            <div className="choose-type-button">
                <Button 
                    title="Order now"
                    onClick={orderNow}
                    icon={BUTTONICONS.right}
                    className="submit-button"
                />
            </div>
        </div>
    }
}

export { TypesBoxView }