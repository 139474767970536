import { PageModel } from "../../Base/PageModel"
import { EXICONS } from "../../Constants/icons"
import { Carousel } from "../../Models/Carousel/Carousel"
import { MissionBox } from "./Company/MissionBox"


class CompanyPage extends PageModel {
  private _carousel: Carousel = new Carousel({
    datas: [
      {
        title: "Top Dog Coatings <c>Story</c>",
        description: "Established in 2021, has rapidly become an integral part of the prestigious Air Step Inc Holdings family. Originally focused on Tower Climbing Construction, we quickly expanded our horizons while maintaining a commitment to excellence. With the successful installation of countless cell tower equipment, Air Step Inc. naturally evolved, introducing a new entity under its umbrella - Top Dog Coatings.",
        image: EXICONS.company,
        selected: true
      },
      {
        title: "Elevating Interiors with <c>Top-Notch Services</c>",
        description: "This evolution reflects our growing understanding of our mission: to leave a profound impact on the world by delivering reliable and trusted services to clients across the United States and beyond. While we offer a range of handyman services, our core expertise lies in crafting luxurious epoxy floor designs and Venetian plaster walls. Our team has honed these skills, prioritizing exceptional customer service, safety, durability, quality, and awe-inspiring designs, all with the aim of achieving ultimate customer satisfaction.",
        image: EXICONS.company,
      },
    ]
  })
  private _mission: MissionBox = new MissionBox()

  pageMounted = () => {
    this._carousel.startRolling()
  };

  get carousel(){
    return this._carousel
  }
  get mission(){
    return this._mission
  }
}

export { CompanyPage };
