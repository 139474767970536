import { Model } from "../../Base/Model";
import { controllers } from "../../Controllers/Controllers";
import { ScrollController } from "../ScrollController";
import { OneInspiration, oneInspirationModel } from "./OneInspiration";

type typesBoxModel = {
    datas: Array<oneInspirationModel>
}

class Inspiration extends Model {
    private _model: typesBoxModel
    private _items: Array<OneInspiration> = []
    private _scroll: ScrollController

    constructor(model: typesBoxModel){
        super()
        this._model = model
        this._scroll = new ScrollController({onScroll: this.onScroll, itIsItem: true, horizontal: true})
        this.makeItems()
    }

    makeItems = () => {
        this._items = this._model.datas.map((ot, i) => {
            const newOt = new OneInspiration({...ot, onPress:this.onPress})
            const nextItem = this._model.datas[i+1]
            if(nextItem && nextItem.selected){
                newOt.customStyle = "pre-selected"
            }
            if(nextItem && newOt.selected){
                nextItem.customStyle = "after-selected"
            }
            const afterNextItem = this._model.datas[i+2]
            if(newOt.selected && afterNextItem){
                    afterNextItem.customStyle = "last-selected"
            }
            if(afterNextItem && afterNextItem.selected){
                newOt.customStyle = "first-selected"
            }
            return newOt
        })
    }

    onPress = (item: OneInspiration) => {
        const findSelected = this._items.find(oi=>oi.selected)
        if(item === findSelected){
            const index = this._items.findIndex(oi=>oi.selected)
            this.showMeModal(index)
            return
        }
        if(findSelected){
            findSelected.setSelected(false)
        }
        item.setSelected(true)
        this.findStyles()
    }

    showMeModal = (index: number) => {
        controllers().modals.photos.show(this.items.map(op=>op.image), index)
    }

    findStyles = () => {
        this._items.forEach(ot=>{
            ot.setCustomStyle("", true)
        })
        this._items.forEach((ot,i) => {
            const newOt = ot
            const nextItem = this._items[i+1]
            if(nextItem && nextItem.selected){
                newOt.customStyle = "pre-selected"
            }
            if(nextItem && newOt.selected == true){
                nextItem.customStyle = "after-selected"
            }
            const afterNextItem = this._items[i+2]
            if(newOt.selected  == true && afterNextItem){
                afterNextItem.customStyle = "last-selected"
            }
            if(afterNextItem && afterNextItem.selected){
                newOt.customStyle = "first-selected"
            }
        })
    }

    onScroll = () => {
        return
        let selectedItem:OneInspiration|null = null
        const {w} = controllers().media
        this._items.filter(ot=>!ot.isEmpty).forEach(ot=>{
            ot.setSelected(false)
            if(this._scroll.pos > ot.offsetLeft){
                selectedItem = ot
            }
        })
        if(selectedItem !== null){
            // @ts-ignore
            selectedItem.setSelected(true)
        }
        this.findStyles()
    }

    get items(){
        return this._items
    }

    get scroll(){
        return this._scroll
    }

}

export { Inspiration }