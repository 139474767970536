import React from "react";
import { ImageView } from "../../Components/ImageView";
import { ICONS } from "../../../Constants/icons";
import { OneContactView } from "./Contact/OneContactView";
import { TitleView } from "../../Components/TitleView";
import { Button } from "../../Components/Button";
import { AskQuestion } from "../../../Controllers/Modals/AskQuestion";
import { controllers } from "../../../Controllers/Controllers";


class ContactBox extends React.Component {

    askQuestion = () => {
        controllers().modals.askQuestion.show()
    }
    callMeBack = () => {
        controllers().modals.callMeBack.show()
    }
    render(){
        return <div className="contact-box">
            <TitleView text="Drop Us a Line"/>
            <div className="contact-box-view">
                <div className="contact-info">
                    <OneContactView 
                        description="Consultation service"
                        title="+1(757) 818-7942"
                        link="tel:+17578187942"
                        ico={ICONS.mobile}
                    />
                    <OneContactView 
                        description="Consultation service"
                        title="+1(210) 459-3168"
                        link="tel:+12104593168"
                        ico={ICONS.mobile}
                    />
                    <OneContactView 
                        description="Customers email"
                        title="1topdogcoatings@gmail.com"
                        link="mailto:1topdogcoatings@gmail.com"
                        ico={ICONS.email}
                    />
                </div>
                <div className="contact-buttons">
                    <Button 
                        title="Call me back"
                        onClick={this.callMeBack}
                        className="action-button"
                    />
                    <div className="button-separator"/>
                    <Button 
                        title="Leave message"
                        onClick={this.askQuestion}
                        className="action-button"
                    />
                    {/* <div className="button-separator"/>
                    <Button 
                        title="Schedule an estimate"
                        onClick={()=>{}}
                        className="action-button"
                    /> */}
                </div>
            </div>
        </div>
    }
}

export { ContactBox }