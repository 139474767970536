import { PageView } from "../../Base/PageView";
import { HomePage } from "../../Controllers/Pages/HomeStack/HomePage";
import { Button } from "../Components/Button";
import { CarouselView } from "../Components/CarouselVIew/CarouselView";
import { InspirationView } from "../Components/InspirationView/InspirationView";
import { TitleView } from "../Components/TitleView";
import { TypesBoxView } from "../Components/TypesBox/TypesBoxView";
import { VideosBoxView } from "../Components/VideoBox/VideosBoxView";
import { CompetetiveBoxView } from "./Home/CompetetiveBoxView";
import { ConsultationBox } from "./PageComponent/ConsultationBox";
import { ContactBox } from "./PageComponent/ContactBox";
import { SolutionsView } from "./PageComponent/SolutionsView";

class HomePageView extends PageView {

    get controller(): HomePage {
        return this.props.controller
    }
    render(){
        const { carousel, videosBox, inspiration, types, competetive, playCurrentVideo, showGallery} = this.controller
        return <div className="page">
            <CarouselView ref={carousel.set} controller={carousel} />
            <TitleView text="About Epoxy Flooring">
                <Button 
                    onClick={playCurrentVideo}
                    title="Watch video"
                    className="helper-button"
                />
                <Button 
                    onClick={showGallery}
                    title="See examples"
                    className="helper-button"
                />
            </TitleView>
            <VideosBoxView ref={videosBox.set} controller={videosBox}/>
            <TitleView text="Choose your type"/>
            <TypesBoxView ref={types.set} controller={types} />
            <TitleView text="Our Competitive Edge"/>
            <CompetetiveBoxView ref={competetive.set} controller={competetive}/>
            <TitleView text="Inspiring Installations">
                <Button 
                    onClick={showGallery}
                    title="Open gallery"
                    className="helper-button"
                />
            </TitleView>
            <InspirationView 
                ref={inspiration.set}
                controller={inspiration}
            />
            <ConsultationBox 
                text="Unlock your flooring transformation with a free consultation. Let's discuss your vision and make it a reality."
            />
            <SolutionsView/>
            <ContactBox/>
        </div>
    }
}

export { HomePageView }