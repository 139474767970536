import React from "react"
import { transformString } from "../../Components/TextTransform";

type oneMissionProps = {
    title: string;
    description: string;
}
class OneMissionView extends React.Component<oneMissionProps> {
    render(){
        const { title, description } = this.props
        return <div className="one-mission">
            <span className="one-mission-title">{transformString(title)}</span>
            <span className="one-mission-description">{description}</span>
        </div>
    }
}

export { OneMissionView }