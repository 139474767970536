import React from "react";
import { Button } from "../../Components/Button";
import { ImageView } from "../../Components/ImageView";
import { ICONS } from "../../../Constants/icons";
import { controllers } from "../../../Controllers/Controllers";

type consultationBoxProps = {
    text: string;
}
class ConsultationBox extends React.Component<consultationBoxProps> {

    getConsultation = () => {
        controllers().modals.getConsultation.show()
    }

    render(){
        const {text} = this.props
        return <div className="consultation">
            <div className="consultation-line"/>
            <div className="consultation-button-box">
                <Button 
                    onClick={this.getConsultation}
                    title="Get a free consultaion"
                    className="action-button"
                />
            </div>
            <div className="consultation-text-box">
                <span className="consultation-text">{text}</span>
            </div>
            <div className="consultation-or-box">
                <span className="consultation-or">OR</span>
            </div>
            <div className="consultation-quote">
                <div className="consultation-back-comming"/>
                <div className="consultation-quote-image-box">
                    <ImageView 
                        source={ICONS.quote}
                    />
                </div>
                <div className="consultation-quote-text-box">
                    <span className="consultation-quote-text">
                        GET A QUOTE
                    </span>
                </div>
            </div>
        </div>
    }
}

export { ConsultationBox }