import { PageView } from "../../Base/PageView";
import { CompanyPage } from "../../Controllers/Pages/CompanyPage";
import { CarouselView } from "../Components/CarouselVIew/CarouselView";
import { TitleView } from "../Components/TitleView";
import { LeadershipBoxView } from "./Company/LeadershipBoxView";
import { MissionBoxView } from "./Company/MissionBoxView";
import { ContactBox } from "./PageComponent/ContactBox";
import { SolutionsView } from "./PageComponent/SolutionsView";

class CompanyPageView extends PageView {

    get controller(): CompanyPage {
        return this.props.controller
    }
    render(){
        const { carousel, mission} = this.controller
        return <div className="page">
            <CarouselView ref={carousel.set} controller={carousel} />
            <TitleView text="Our mission"/>
            <MissionBoxView ref={mission.set} controller={mission}/>
            <SolutionsView/>
            <TitleView text="Our leadership"/>
            <LeadershipBoxView/>
            <ContactBox/>
        </div>
    }
}

export { CompanyPageView }