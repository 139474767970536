import React from "react"
import { ImageView } from "../../Components/ImageView";

type oneLeaderProps = {
    image?: string;
    name: string;
    title: string;
}
class OneLeaderView extends React.Component<oneLeaderProps> {

    render(){
        const { image, name, title} = this.props
        return <div className="one-leader">
            <div className="one-leader-image">
                {image && <ImageView
                    className="one-leader-img"
                    source={image}
                />}
            </div>
            <span className="one-leader-name">{name}</span>
            <span className="one-leader-title">{title}</span>
        </div>
    }
}

export { OneLeaderView }