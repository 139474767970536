import { controllers } from '../Controllers/Controllers';
import { Model } from './Model';

class PageModel extends Model {
  private readonly _pageName: string;

  constructor(pageName: string) {
    super();
    this._pageName = pageName;
    this.pageMounted = this.pageMounted.bind(this);
  }

  get pageName() {
    return this._pageName;
  }

  onMount = () => {
    controllers().menu.chooseCurrentPage()
  }
  onUnmount = () => {}
  pageMounted = () => {};
  pageFocus = () => {};
  pageBlur = () => {};
  willUnmount = () => {};
}

export { PageModel };
