import React from "react"
import { controllers } from "../../Controllers/Controllers";

type footerSectionProps = {
    title: string;
    children: any
}
type footerSectionState = {
    isVisible: boolean
}

class FooterSection extends React.Component<footerSectionProps> {
    state: footerSectionState = {
        isVisible: false
    }
    toggle = () => {
        if(controllers().media.w>800){
            return
        }
        this.setState({isVisible: !this.state.isVisible})
    }
    render(){
        const { title, children} = this.props
        const { isVisible } = this.state
        return <div className="footer-section">
            <div className={`footer-section-title-box ${title==="" ? 'empty-line' : ''}`} onClick={this.toggle}>
                <div className="footer-section-title">{title}</div>
                {(window.innerWidth<=800 && title!=="") && <div className="plus">+</div>}
            </div>
            <div className={`footer-section-items ${isVisible ? 'mobile-section-visible': 'mobile-section-hidden'}`}>
                {children}
            </div>
        </div>
    }
}

export { FooterSection }