
export const transformString = (inputString:string) => {
    const jsxElements = [];
    const parts = inputString.split(/(<c>.*?<\/c>)/);
  
    for (const part of parts) {
      if (part.startsWith('<c>')) {
        const textInsideC = part.substring(3, part.length - 4); // Remove <c> and </c>
        jsxElements.push(
          <div key={jsxElements.length} className="text-tranform-color">
            {textInsideC}
          </div>
        );
      } else {
        jsxElements.push(part);
      }
    }
  
    return <>{jsxElements}</>;
  }