import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS, ICONS } from "../../Constants/icons";
import { AppHeaderController } from "../../Controllers/AppHeaderController";
import { controllers } from "../../Controllers/Controllers";
import { Button } from "../Components/Button";
import { ImageView } from "../Components/ImageView";
import { Logotype } from "../Components/Logotype";
import { MenuView } from "./MenuView";

class AppHeaderView extends ViewItem {

    get controller():AppHeaderController{
        return this.props.controller
    }
    render(){
        const { menu } = this.controller
        return <div className="app-header">
            <div className="app-header-box">
                {/* LOGOTYPE */}
                <div className="app-logo">
                    <Logotype/>
                </div>
                {/* MENU  */}
                <div className="app-menu">
                    <MenuView ref={menu.set} controller={menu} />
                </div>
                {/* CONTROLS */}
                <div className="app-controls">
                    {window.innerWidth<=800 && <Button
                        className="icon-button menu-button"
                        title=""
                        icon={BUTTONICONS.menu}
                        onClick={menu.toggle}
                    />}
                    <div className="simple-consult" onClick={controllers().modals.getConsultation.show}>
                        <ImageView 
                            className="simple-consult-ico"
                            source={BUTTONICONS.simpleconsult}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export { AppHeaderView }