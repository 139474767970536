import { Model } from "../../Base/Model";
import { OnePagging } from "./OnePagging";


type carouselPaggingModel = {
    onPress?: (ind: number)=>void
}

class CarouselPagging extends Model {
    private _model: carouselPaggingModel
    private _items: Array<OnePagging> = []
    
    constructor(model: carouselPaggingModel){
        super()
        this._model = model
    }
    makeLength = (len: number) => {
        this._items = []
        for (let index = 0; index < len; index++) {
            this._items.push(new OnePagging({number: index, selected: index === 0, onPress: this.onItemPress}))
        }
    }

    setCurrentSelected = (num: number) => {
        this._items.forEach(op=>{
            op.setSelected(op.number === num)
        })
    }

    onItemPress = (num: number) => {
        if(this._model.onPress){
            this._model.onPress(num)
        }
    }

    get items(){
        return this._items
    }

    get currentSelectedIndex(){
        return this._items.find(it=>it.selected)
    }
}

export { CarouselPagging }