import React from 'react';
import './assets/styles/App.css';
import './assets/styles/design.css';
import './assets/styles/fonts.css'
import './assets/styles/customstyles/appheaderStyle.css'
import './assets/styles/customstyles/carouselstyle.css'
import './assets/styles/customstyles/videosstyle.css'
import './assets/styles/customstyles/typesStyle.css'
import './assets/styles/customstyles/consultationStyle.css'
import './assets/styles/customstyles/contactStyle.css'
import './assets/styles/customstyles/footerStyle.css'
import './assets/styles/customstyles/solutionStyle.css'
import './assets/styles/customstyles/inspirationStyle.css'
import './assets/styles/customstyles/competetiveStyle.css'
import './assets/styles/customstyles/missionStyle.css'
import './assets/styles/customstyles/leadershipStyle.css'
import './assets/styles/customstyles/galleryStyle.css'
import './assets/styles/modals.css'
import {Provider} from 'react-redux';
import store from './redux';
import { controllers } from './Controllers/Controllers';
import { Helpers } from './Core/Helpers';
import { MainNavigation } from './Core/MainNavigation';
import { AppStateController } from './Core/AppStateController';

function App() {
  return <div className="App" id="APP_CONTROLLER">
          <div className="App-box horizontal-scrollbar" id="mainscroll" ref={controllers().scroll.set}>
              <Provider store={store}>
                <AppStateController/>
                <Helpers/>
                <MainNavigation/>
              </Provider>
          </div>
    </div>
}

export default App;
