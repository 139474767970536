export const ICONS = {
  logotype: require('../assets/img/main/Logotype.png'),
  justDog: require('../assets/img/main/JustDog.png'),
  // quote:  require('../assets/img/main/quotenew.png'),
  quote:  require('../assets/img/main/quotebox.png'),
  mobile:  require('../assets/img/main/mobilephone.png'),
  email:  require('../assets/img/main/emailico.png'),
  play:  require('../assets/img/main/playme.png'),
  competetive:  require('../assets/img/main/competetivebox.png'),
  vision:  require('../assets/img/main/vision.png'),
  focus:  require('../assets/img/main/focus.png'),
};

export const BUTTONICONS = {
  menu:  require('../assets/img/buttons/MenuButton.png'),
  simpleconsult:  require('../assets/img/buttons/simpleconsult.png'),
  right:  require('../assets/img/buttons/buttonRight.png'),
  rightAngle:  require('../assets/img/buttons/rightAngle.png'),
  modalclose:  require('../assets/img/buttons/modalclose.png'),
}

export const EXICONS = {
  mainbg: require('../assets/img/example/highqualitygold.png'),
  contertops: require('../assets/img/example/contertops.png'),
  company: require('../assets/img/example/company.png'),
  gallery: require('../assets/img/example/gallery.png'),
  ot1: require('../assets/img/example/ot1.png'),
  ot2: require('../assets/img/example/ot2.png'),
  ot3: require('../assets/img/example/ot3.png'),
  ot4: require('../assets/img/example/ot4.png'),
  ii1: require('../assets/img/example/ii1.png'),
  ii2: require('../assets/img/example/ii2.png'),
  ii3: require('../assets/img/example/ii3.png'),
  ii4: require('../assets/img/example/ii4.png'),
  ii5: require('../assets/img/example/ii5.png'),
  vo1: require('../assets/img/example/vo1.png'),
  convideo: require('../assets/img/example/convideo.png'),
  contype1: require('../assets/img/example/contype1.png'),
  contype2: require('../assets/img/example/contype2.png'),
  contype3: require('../assets/img/example/contype3.png'),
  contype4: require('../assets/img/example/contype4.png'),
  coni1: require('../assets/img/example/coni1.png'),
  coni2: require('../assets/img/example/coni2.png'),
  coni3: require('../assets/img/example/coni3.png'),
  coni4: require('../assets/img/example/coni4.png'),
  coni5: require('../assets/img/example/coni5.png'),
  gi1: require('../assets/img/example/gi1.png'),
  gi2: require('../assets/img/example/gi2.png'),
  gi3: require('../assets/img/example/gi3.png'),
  gi4: require('../assets/img/example/gi4.png'),
  gi5: require('../assets/img/example/gi5.png'),
  gi6: require('../assets/img/example/gi6.png'),
  gi7: require('../assets/img/example/gi7.png'),
  gi8: require('../assets/img/example/gi8.png'),
  gi9: require('../assets/img/example/gi9.png'),
}