import React from 'react'
import { controllers } from '../Controllers/Controllers'
import { OrderNowModal } from '../Views/Modals/OrderNowModal'
import { GetConsiltationModal } from '../Views/Modals/GetConsultationModal'
import { AskQuestionModal } from '../Views/Modals/AskQuestionModal'
import { CallMeBackModal } from '../Views/Modals/CallMeBackModal'
import { PhotosModalView } from '../Views/Modals/PhotosModalView'

// import { TrailerModalView } from '../Models/Helpers/TrailerModalView'

class Helpers extends React.Component {
    render(){
        const { loader, modals } = controllers()
        const { photos, orderModal, getConsultation, askQuestion, callMeBack} = modals
        // main navigation
        return <div className="helpers">
            <div className="helpers-box">
                <PhotosModalView ref={photos.set} controller={photos}/>
                <OrderNowModal ref={orderModal.set} controller={orderModal}/>
                <GetConsiltationModal ref={getConsultation.set} controller={getConsultation}/>
                <AskQuestionModal ref={askQuestion.set} controller={askQuestion}/>
                <CallMeBackModal ref={callMeBack.set} controller={callMeBack}/>
                {/* <Alert/>
                <MainLoader ref={loader.set} controller={loader}/> */}
            </div>
        </div>
    }
}

export { Helpers }