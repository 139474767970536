import React from "react";
import { ViewItem } from "../../../Base/ViewItem";

type onePaggingProps = {
    num: number;
    selected:boolean;
    onPress: (num: number)=>void
}

class OnePaggingView extends React.Component<onePaggingProps> {
    onPress = () => {
        this.props.onPress(this.props.num)
    }
    render(){
        const {selected} = this.props
        return <div className="one-pagi-clicked" onClick={this.onPress}>
            <div className={`one-pagi ${selected ? 'one-pagi-selected' : ''}`}>
                <div className={`one-pagi-proccess running`}/>
            </div>
        </div>
    }
}

export { OnePaggingView }