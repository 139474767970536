import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS } from "../../Constants/icons";
import { OrderModal } from "../../Controllers/Modals/OrderModal";
import { Button } from "../Components/Button";
import { ImageView } from "../Components/ImageView";
import { ModalSuit } from "./ModalSuit";


class OrderNowModal extends ViewItem {

    get controller(): OrderModal {
        return this.props.controller
    }

    render(){
        const { hide, visible, title, type } = this.controller
        return <ModalSuit title={title} hide={hide} visible={visible}>
                <span className="modal-title">Your type</span>
                {type && <div className="type-choosed one-type-selected">
                <div className="one-type-box">
                    <ImageView 
                        source={type}
                        className="one-type-img"
                    />
                </div>
            </div>}
            <div className="modal-input-box">
                <input 
                    name="name"
                    placeholder="Enter your name"
                    className="modal-input"
                />
            </div>
            <div className="modal-input-box">
                <input 
                    name="phone"
                    placeholder="Enter your phone"
                    className="modal-input"
                />
            </div>
            <div className="modal-submit">
                <Button 
                    title="Order"
                    onClick={()=>{}}
                    isSubmit
                    className="submit-button"
                    icon={BUTTONICONS.right}
                />
            </div>
        </ModalSuit>
    }
}

export { OrderNowModal }