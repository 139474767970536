import { AppSettings } from '../Constants/AppSettings';
import {
  authBody,
  baseResponse,
  changeLanguageBody,
  checkDeviceUpdatedBody,
  getMoviesBody,
  noBody,
  saveTimeBody,
  save_device_tokenType,
  tokenBody,
} from '../DataTypes/BaseResponse';
import { fetchData } from '../Helpers/FetchData';

export type watchChannelBody = {
  userToken: string;
  channelToken: string;
};

export class UserDataProvider {
  static authorization(body: authBody) {
    return fetchData('user/sign_in', 'POST', {
      country: body.country,
      phonenumber: body.phone,
    });
  }
  static getCode(body: authBody) {
    return fetchData('user/getcode', 'POST', {
      country: body.country,
      phonenumber: body.phone,
    });
  }
  static check(body: tokenBody) {
    return fetchData('user/token', 'POST', {
      userToken: body.userToken,
    });
  }
  static logout(body: tokenBody) {
    return fetchData('user/logout', 'POST', {
      userToken: body.userToken,
    });
  }

  static userInfo(body: tokenBody) {
    return fetchData('user/getUserInfo', 'POST', {
      userToken: body.userToken,
    });
  }
  static changeLanguage(body: changeLanguageBody) {
    return fetchData('user/set_language', 'POST', {
      userToken: body.userToken,
      language: body.language,
    });
  }
  static save_user_token(body: save_device_tokenType) {
    return fetchData('save_device_token', 'POST', body);
  }

  static watchChannel(body: watchChannelBody) {
    // return `${AppSettings.endpoint}/user/watch/${body.userToken}/${body.channelToken}`
    return `${AppSettings.endpoint}/watch/live/${body.userToken}/${body.channelToken}`;
  }

  static saveTimeRequest(body: saveTimeBody) {
    return fetchData('user/savetime', 'POST', {
      userToken: body.userToken,
      movie_hash: body.movie_hash,
      type: body.stream_type,
      time: body.time,
    });
  }

  static checkDeviceUpdated(body: checkDeviceUpdatedBody) {
    return fetchData('user/check_device', 'POST', {
      deviceId: body.deviceId,
      deviceName: body.deviceName
    });
  }


  static listChannels(body: any){
    return fetchData(
        'user/live_channels',
        'POST',
        {
            userToken: body.userToken,
            country: body.country,
            category: body.category,
            pageIndex: body.pageIndex,
            pageSize: body.pageSize
        }
    )
  }
  static loadCategoryMovies(body: noBody){
    return fetchData(
        'user/movie_categories',
        'GET'
    )
  }

  static getMovies(body: getMoviesBody){
    return fetchData(
        'user/movie_channels',
        'POST',
        {
            category_id: body.category_id,
            pageIndex: body.pageIndex,
            pageSize: body.pageSize,
            sortByYear: body.sortByYear,
            sortByRating: body.sortByRating,
            filterYear: body.filterYear,
            filterCountry: body.filterCountry,
            withPhotoOnly: body.withPhotoOnly,
        },
        // true
    )
  }

  static getSeries(body: getMoviesBody){
      return fetchData(
          'user/series_channels',
          'POST',
          {
              category_id: body.category_id,
              pageIndex: body.pageIndex,
              pageSize: body.pageSize,
              sortByYear: body.sortByYear,
              sortByRating: body.sortByRating,
              filterYear: body.filterYear,
              filterCountry: body.filterCountry,
              withPhotoOnly: body.withPhotoOnly,
          },
          true
      )
}

}

export async function loadData(
  dataProvider: (body: any) => any,
  body: {}
): Promise<baseResponse> {
  try {
    const response: baseResponse = await dataProvider(body);
    if (response.statusCode < 200) {
      // system error exception
      // console.error("STATUS CODE", response.statusMessage)
    }
    return response;
  } catch (e) {
    console.log('error', e);
    throw e;
  }
}
