import { Model } from "../../Base/Model";

export type oneInspirationModel = {
    image: string;
    isEmpty?: boolean
    selected?: boolean;
    customStyle?: string;
    onPress?:(item: OneInspiration)=>void
}

class OneInspiration extends Model {
    private _model: oneInspirationModel
    private _selected: boolean;
    private _domRef: any;
    private _offsetLeft: number = 0;

    constructor(model: oneInspirationModel){
        super()
        this._model = model
        this._selected = model.selected || false
    }

    get image(){
        return this._model.image
    }

    get isEmpty(){
        return this._model.isEmpty
    }

    get customStyle(){
        return this._model.customStyle
    }
    set customStyle(val){
        this._model.customStyle = val
        this.updateMe()
    }
    setCustomStyle = (e: string, update: boolean = false) => {
        this._model.customStyle = e
        if(update){
            this.updateMe()
        }
    }


    get selected(){
        return this._selected
    }

    setSelected = (bool: boolean) => {
        if(this._selected === bool){
            return
        }
        this._selected = bool
        this.updateMe()
    }
    setDomRef = (e:any) => {
        this._domRef = e
        this._offsetLeft = e && e.offsetLeft ? e.offsetLeft : 0
    }

    get offsetLeft(){
        return this._offsetLeft
    }
    
    onPress = () => {
        if(this._model.onPress){
            this._model.onPress(this)
        }
    }
}

export { OneInspiration }