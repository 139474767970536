import { UpdateComponent } from "../../Base/UpdateComponent";
import { RW } from "../../Helpers/LangHelper";
import { LangItem } from "../../Helpers/LangItem";
import { UPDATE } from "../../Helpers/constants";
import { ImageView } from "./ImageView";
import { Loading } from "./Loading";


type buttonProps = { 
    children?: any
    className?: string;
    title: string;
    isSubmit?: boolean
    icon?: string;
    onClick: (e?:any)=>void;
    additionalMark?: boolean;
}

type buttonState = {
    loading: boolean
}

class Button extends UpdateComponent {
    props: buttonProps
    state: buttonState
    _ref: any

    constructor(props: buttonProps){
        super(props)
        this.props = props
        this.type = UPDATE.LANG
        this.state = { 
            loading: false
        }
    }

    loading = (loading: boolean) => {
        this.setState({loading})
    }

    setButtRef = (ref:any) => {
        this._ref = ref
    }

    get ref(){
        return this._ref
    }

    render() {
        const { title, onClick, className, isSubmit, icon, additionalMark} = this.props
        const { loading } = this.state
        return <div className={`button ${className} ${additionalMark ? "mark-button" : ""}`}>
            <button 
                ref={this.setButtRef}
                onClick={onClick} 
                className="button-main" 
                type={isSubmit ? "submit" : "button"}>
                    {icon && <ImageView source={icon} withoutLoader className="button-icon" />}
                    {/* <div style={{width: 10, height: 10, background:'red'}}/> */}
                    {/* {loading ? <Loading size="sm" color={"white"}/> : RW(title)} */}
                    {loading ? <Loading size="sm" color={"white"}/> : title}
            </button>
            {additionalMark && <div className="mark-box"></div>}
        </div>
    }
}

export { Button}