import React from 'react'
import { AppHeaderView } from '../Views/AppHeader/AppHeaderView';
import { controllers } from '../Controllers/Controllers';
import { AppFooterView } from '../Views/AppFooter/AppFooterView';


type corePage = {
    children: any;
    history?:any;
}

class Core extends React.Component {
    props: corePage
    
    constructor(props:corePage){
        super(props)
        this.props = props
    }
    render(){
        return <div className="core">
            <AppHeaderView ref={controllers().appHeader.set} controller={controllers().appHeader} />
            <div className="core-box">
                {this.props.children}
            </div>
            <AppFooterView ref={controllers().footer.set} controller={controllers().footer}/>
        </div>
    }
}

export { Core }