import { ViewItem } from "../../Base/ViewItem";
import { FooterSection } from "./FooterSection";
import { SectionItemView } from "./SectionItemView";


class AppFooterView extends ViewItem {

    render(){
        return <div className="footer">
            <div className="footer-line"/>
            <div className="footer-content">
                <FooterSection title="Epoxy">
                    <SectionItemView title="About epoxy" link="/#epoxy"/>
                    <SectionItemView title="Choose your type" link="/#epoxytype"/>
                    <SectionItemView title="Our Competitive Edge" link="/#competitive"/>
                    <SectionItemView title="Inspiring Installations" link="/#gallery"/>
                    <SectionItemView title="Get a quote" link="/#quote"/>
                    <SectionItemView title="Drop a line" link="/#contact"/>
                </FooterSection>
                <FooterSection title="Countertops">
                    <SectionItemView title="About countertops" link="/countertops#about"/>
                    <SectionItemView title="Countertops selection" link="/countertops#about"/>
                    <SectionItemView title="Countertops quote" link="/countertops#quote"/>
                </FooterSection>
                <FooterSection title="Navigation">
                    <SectionItemView title="Company" link="/company"/>
                    <SectionItemView title="Portfolio" link="/portfolio"/>
                    <SectionItemView title="Prices calculator" link="/calculator"/>
                </FooterSection>
                <FooterSection title="">

                </FooterSection>
            </div>
            <div className="footer-line"/>
            <div className="footer-main-end">Copyright © 2023</div>
        </div>
    }
}

export { AppFooterView }