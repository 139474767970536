import React from "react";
import { OneSolutionView } from "./Solutions/OneSolutionView";
import { TitleView } from "../../Components/TitleView";
import { Button } from "../../Components/Button";
import { BUTTONICONS } from "../../../Constants/icons";
import { controllers } from "../../../Controllers/Controllers";


class SolutionsView extends React.Component {

    askQuestion = () => {
        controllers().modals.askQuestion.show()
    }
    render(){
        return <div className="solutions">
            <TitleView text="Our Solutions">
                <Button 
                    onClick={this.askQuestion}
                    title="Ask question"
                    className="helper-button"
                />
            </TitleView>
            <div className="solutions-view no-scroll">
                <OneSolutionView 
                    title="Epoxy flakes"
                    description="Experience the fusion of durability and aesthetics with our Metallic Epoxy Flooring service. We blend the resilience of epoxy with enchanting metallic pigments, turning your space into a breathtaking work of art that stands the test of time."
                    link="/metallic-epoxy"
                />
                <OneSolutionView 
                    title="Metallic epoxy flooring"
                    description="Our Metallic Epoxy Flooring service combines the durability of epoxy with captivating metallic pigments to transform your space into a stunning work of art."
                    link="/metallic-epoxy"
                />
                <OneSolutionView 
                    title="Countertop Epoxy"
                    description="Elevate the beauty and durability of your countertops with our premium epoxy solutions. Our expert craftsmanship and high-quality materials come together to create surfaces that shine with elegance and stand the test of time."
                    link="/countertops"
                />
                <OneSolutionView 
                    title="Venetian Plaster Walls"
                    description="Experience timeless elegance and sophistication with our Venetian plaster wall treatments. Our skilled artisans craft these luxurious walls, adding texture, depth, and a touch of Italy to your space. Elevate your surroundings with the artistry of Venetian plaster."
                    // link="/countertops"
                />
                <OneSolutionView 
                    title="LED light installation"
                    description="Our Lighting Ceiling service illuminates your space with a perfect blend of functionality and design, offering custom solutions that elevate both the ambiance and functionality of your environment."
                    // link="/lighting-ceiling"
                />
            </div>
            <div className="solutions-arrows">
                <Button 
                    onClick={()=>{}}
                    title=""
                    icon={BUTTONICONS.rightAngle}
                    className="icon-button pagi-btn reverse-vertical"
                />
                <Button 
                    onClick={()=>{}}
                    title=""
                    icon={BUTTONICONS.rightAngle}
                    className="icon-button pagi-btn"
                />
            </div>
        </div>
    }
}

export { SolutionsView }