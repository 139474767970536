import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS, ICONS } from "../../Constants/icons";
import { GetConsultation } from "../../Controllers/Modals/GetConsultation";
import { OrderModal } from "../../Controllers/Modals/OrderModal";
import { Button } from "../Components/Button";
import { ImageView } from "../Components/ImageView";
import { OneContactView } from "../Pages/PageComponent/Contact/OneContactView";
import { ModalSuit } from "./ModalSuit";


class GetConsiltationModal extends ViewItem {

    get controller(): GetConsultation {
        return this.props.controller
    }

    render(){
        const { hide, visible, title } = this.controller
        return <ModalSuit title={title} hide={hide} visible={visible}>
                <span className="modal-title">Get consultation</span>
                <OneContactView 
                    description="Consultation service"
                    title="+1(757) 818-7942"
                    link="tel:+17578187942"
                    ico={ICONS.mobile}
                />
                <span className="modal-description">Ready for expert guidance? We're delighted that you're considering a consultation with us! To get started with a complimentary phone call consultation, simply share your name and phone number below. Our seasoned consultants are eager to connect with you and provide valuable insights. Your journey to success begins here!</span>
                <div className="modal-input-box">
                    <input 
                        name="name"
                        placeholder="Enter your name"
                        className="modal-input"
                    />
                </div>
                <div className="modal-input-box">
                    <input 
                        name="phone"
                        placeholder="Enter your phone"
                        className="modal-input"
                    />
                </div>
                <div className="modal-submit">
                    <Button 
                        title="Submit"
                        onClick={()=>{}}
                        isSubmit
                        className="submit-button"
                        icon={BUTTONICONS.right}
                    />
                </div>
            </ModalSuit>
    }
}

export { GetConsiltationModal }