import { Model } from "../../Base/Model";
import { Timeout } from "../../Base/Timeout";
import { CarouselItem, carouselItemModel } from "./CarouselItem";
import { CarouselPagging } from "./CarouselPagging";

type carouselModel = {
    datas: Array<carouselItemModel>
}

export const CAROUSEL_TIMEOUT = 5000

class Carousel extends Model {
    private _model: carouselModel
    private _items: Array<CarouselItem> = []
    private _timeout: Timeout = new Timeout(CAROUSEL_TIMEOUT)
    private _scrollRef: any
    private _isActive: boolean = false
    private _pagging: CarouselPagging

    constructor(model: carouselModel){
        super()
        this._model = model
        this._pagging = new CarouselPagging({onPress: ()=>{}})
        this.makeItems()
    }

    startRolling = () => {
        this._timeout.set(this.makeRolling)
    }
    stop = () => {
        this._timeout.clear()
    }
    makeRolling = () => {
        const index = this.getSelectedIndex
        const selectedNow = this._items[index]
        const find = this._items[index+1] || this._items[0]
        selectedNow.setSelected(false)
        find.setSelected(true)
        this.setCurrentSelected()
        this.scrollToCurrent()
        this.startRolling()
    }

    setSelectedIndex = (num: number) => {
        const current = this.getSelected
        if(current){
            current.setSelected(false)
        }
        const next = this._items[num]
        if(next){
            next.setSelected(true)
        }
        this._pagging.setCurrentSelected(num)
    }
    setCurrentSelected = () => {
        this._pagging.setCurrentSelected(this.getSelectedIndex)
    }
    scrollToCurrent = () => {
        const currentItem = this.getSelected
        if(currentItem && this._scrollRef){
            this._scrollRef.scroll({
                left: currentItem.offsetLeft,
                behavior: 'smooth', // Use 'auto' for instant scrolling
            });
        }
    }

    makeItems = () => {
        this._items = this._model.datas.map(ci=>new CarouselItem(ci))
        this._pagging.makeLength(this._items.length)
        // this.updateMe()
    }

    setScrollRef = (e:any) => {
        this._scrollRef = e
    }
    get getSelectedIndex(){
        return this._items.findIndex(ci=>ci.selected)
    }
    get getSelected(){
        return this._items.find(ci=>ci.selected)
    }
    get items(){
        return this._items
    }
    get pagging(){
        return this._pagging
    }
}

export { Carousel }