import { ViewItem } from "../../../Base/ViewItem";
import { OneType } from "../../../Models/TypesBox/OneType";
import { ImageView } from "../ImageView";


class OneTypeView extends ViewItem {

    get controller(): OneType {
        return this.props.controller
    }
    render(){
        const { selected, image, isEmpty, setDomRef } = this.controller
        return <div className={`one-type ${isEmpty ? 'one-type-empty' : ''} ${selected ? 'one-type-selected' : ''}`} ref={setDomRef}>
            <div className="one-type-box">
                {!isEmpty && <ImageView 
                    source={image}
                    className="one-type-img"
                />}
            </div>
        </div>
    }
}

export { OneTypeView }