import React from "react"
import { AppSettings } from "../../Constants/AppSettings";
import { controllers } from "../../Controllers/Controllers";

type imageTypeProps = {
    source?: string;
    alt?: string;
    altPhoto?:string;
    altWithClass?: boolean;
    className?: string;
    withoutLoader?: boolean;
    CustomLoader?: any;
    customDiv?: string
    customStyle?: any;
    onPress?: ()=>void
}

type imageTypeState = {
    loaded: boolean;
    error: boolean;
}

const makeSource = (source:any) => {
    if(typeof source !== "string"){
        return source
    }
    return source?.indexOf(AppSettings.endpoint)===-1 ? source : source 
}

class ImageView extends React.Component<imageTypeProps> {
    
    props: imageTypeProps;
    state: imageTypeState;
    image = new Image();
    constructor(props: imageTypeProps){
        super(props)
        this.props = props
        this.state = { 
            loaded: false,
            error: false
        }
    }
    
    componentDidMount(){
        // this.image.addEventListener('load', this.onLoadImage)
        // this.image.addEventListener('error', this.onLoadImage)
        this.image = new Image();
        // this.setState({loaded: false, error: false})
        // this.props.source = this.props.source?.indexOf('http')===-1 
        // ? this.props.source 
        // : this.props.source+"/"+controllers().media.w
        this.preloadImage()
    }

    componentWillUnmount(){
        this.image.onload = null
        this.image.onerror = null
        // this.image.removeEventListener('load', this.onLoadImage)
        // this.image.removeEventListener('error', this.onLoadImage)
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.source !== this.props.source){
            this.setState({loaded: false, error: false})
            this.preloadImage()
        }
    }

    onLoadImage = () => {
        this.makeLoaded()
    }

    onErrorImage = () => {
        // console.error('ON ERROR')
        this.makeError(true)
    }

    makeError = (error: boolean = true) => {
        this.setState({error, loaded: true})
    }

    makeLoaded = (loaded: boolean = true) => {
        this.setState({loaded})
    }

    preloadImage = () => {
        const {source} = this.props
        if(!source){
            this.makeError(true)
            return
        }
        // const readSource = this.props.source?.indexOf('http')===-1 ? source : source + "/" + controllers().media.w
        this.image.onload = this.onLoadImage
        this.image.onerror = this.onErrorImage
        this.image.src = makeSource(source)
    }

    render(){
        const { loaded, error } = this.state
        const { source, withoutLoader, className, altPhoto, altWithClass, CustomLoader, customDiv, customStyle} = this.props
        // const MyImage = this.image
        if(error){
            if(altPhoto && altWithClass){
                return <ImageView className={className} source={altPhoto}/>
            } else {
                return <div className="image-error">
                    <ImageView className={className} source={altPhoto ? altPhoto : "BASE_ICONS.noIcon"}/>
                </div>
            }
        }
        if(!loaded){
            if(withoutLoader){
                return null
            }
            if(CustomLoader){
                return CustomLoader
            }
            return <div className="image-loading">
                {/* <Loading size="lg"/> */}
                <div className="great-loader"/>
            </div>
        }
        if(customDiv){
            return <div className={customDiv}  onClick={this.props.onPress}>
                <img src={makeSource(source)} style={customStyle} alt="no photo" className={className}/>
            </div>
        }
        // return <MyImage/>;
        return <img onClick={this.props.onPress} src={makeSource(source)} alt="no photo" style={customStyle} className={className}/>
    }
}

export { ImageView }