import { Model } from "../Base/Model";
import { controllers } from "../Controllers/Controllers";

type scrollControllerModel = {
  onScroll?: (e: any) => void;
  horizontal?: boolean;
  itIsItem?: boolean;
};

class ScrollController extends Model {
  private _scrollRef: any;
  private _model: scrollControllerModel;
  private _scrollHeight: number;
  private _pos: number;
  private _contentHeight: number;
  constructor(model: scrollControllerModel) {
    super();
    this._model = model;
    this._scrollRef = null;
    this._pos = 0;
    this._scrollHeight = 0;
    this._contentHeight = 0;
  }
  get pos() {
    return this._pos;
  }
  get scrollHeight() {
    return this._scrollHeight;
  }
  get contentHeight() {
    return this._contentHeight;
  }
  updateScrollHeight = (scrollHeight: number) => {
    this._scrollHeight = scrollHeight;
  };

  onLayout = (e: any) => {
    var { y, height } = e.nativeEvent.layout;
    this._pos = y;
    this._contentHeight = height;
  };
  setScrollRef = (ref: any) => {
    this._scrollRef = ref;
    this.makeListeners();
  };
  get ref() {
    return this._scrollRef;
  }

  scroll = (y: number) => {
    if (this._scrollRef === null) {
      return;
    }
    this._pos = y;
    if(this._model.horizontal){
      this._scrollRef.scroll(y, 0)
    } else {
      this._scrollRef.scroll(0, y)
    }
  };

  updateParams = (y: number, height: number) => {
    this._pos = y;
    this._scrollHeight = height;
  };

  scrollToCurrentPosition = () => {
    if(this._pos){
      controllers().scroll.scroll(this._pos)
    }
  }

  onScroll = (event: any) => {
    let layoutMeasurement = {height: 0, width: 0}
    let contentOffset = {x: 0, y:0}
    let contentSize = {height: 0, width: 0}
    if(this._model.itIsItem){
      layoutMeasurement = {
        height: event.target.clientHeight,
        width: event.target.clientWidth
      };
      contentOffset = {
        y: event.target.scrollTop,
        x: event.target.scrollLeft,
      };
      contentSize = {
        width: event.target.scrollWidth,
        height: event.target.scrollHeight,
      };
    } else {
      layoutMeasurement = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      contentOffset = {
        y: window.scrollY,
        x: window.scrollX,
      };
      contentSize = {
        width: document.documentElement.scrollWidth,
        height: document.documentElement.scrollHeight
      };
    }

    this._pos = this._model.horizontal ? contentOffset.x : contentOffset.y;
    this._scrollHeight = layoutMeasurement.height;
    this._contentHeight = contentSize.height;
    if (this._model.onScroll) {
      this._model.onScroll(event);
    }
    
  };

  makeListeners = () => {
    // if(this._model.onScroll && this._scrollRef !== null){
    //     this._scrollRef.addEventListener('scroll', this._model.onScroll)
    // }
  };
  removeListeners = () => {
    // if(this._model.onScroll){
    //     this._scrollRef.removeEventListener('scroll', this._model.onScroll)
    // }
  };

  getHeight = () => {
    if (this._scrollRef === null) {
      return;
    }
    try {
      return this._scrollRef.offsetHeight;
    } catch (e) {
      console.error('Height null');
      return null;
    }
  };
  getWidth = () => {
    if (this._scrollRef === null) {
      return;
    }
    try {
      return this._scrollRef.offsetWidth;
    } catch (e) {
      console.error('Height null');
      return null;
    }
  };
}

export { ScrollController };
