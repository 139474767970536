import React from "react";

type sectionItemProps = {
    title: string;
    link: string;
}

class SectionItemView extends React.Component<sectionItemProps> {
    render() {
        const { title, link } = this.props
        return <div className="section-item">
            <a className="section-item-link" href={link}>
                {title}
            </a>
        </div>
    }
}

export { SectionItemView }