import React from "react";
import { ViewItem } from "../../../Base/ViewItem";
import { OnePagging } from "../../../Models/Carousel/OnePagging";

class OnePage extends ViewItem {
    mounted: boolean = false
    get controller(): OnePagging{
        return this.props.controller
    }
    componentDidMount(): void {
        if(this.mounted ){
            return
        }
        this.controller.mounted()
    }
    render(){
        const { selected, onPress, isRunning, number} = this.controller
        return <div className="one-pagi-clicked" onClick={onPress}>
            <div className={`one-pagi ${selected ? 'one-pagi-selected' : ''}`}>
                <div className={`one-pagi-proccess ${isRunning ? 'running' : 'stoped'}`}/>
            </div>
        </div>
    }
}

export { OnePage }