import { ViewItem } from "../../../Base/ViewItem";
import { OneInspiration } from "../../../Models/Inspiration/OneInspiration";
import { OneType } from "../../../Models/TypesBox/OneType";
import { ImageView } from "../ImageView";


class OneInspirationView extends ViewItem {

    get controller(): OneInspiration {
        return this.props.controller
    }
    render(){
        const { selected, image, isEmpty, customStyle, onPress, setDomRef } = this.controller
        return <div className={`one-inspiration ${isEmpty ? 'one-type-empty' : ''} ${customStyle||""} ${selected ? 'one-type-selected' : ''}`} 
                    ref={setDomRef} 
                    onClick={onPress}>
            <div className="one-inspiration-box">
                {!isEmpty && <ImageView 
                    source={image}
                    className="one-inspiration-img"
                />}
            </div>
        </div>
    }
}

export { OneInspirationView }