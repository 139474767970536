import React from "react"
import { ImageView } from "../Components/ImageView";
import { BUTTONICONS } from "../../Constants/icons";


type modalSuitProps = {
    children?: any
    title: string;
    hide: ()=>void;
    visible: boolean;
    customClass?: string;
}
class ModalSuit extends React.Component<modalSuitProps> {
    render(){
        const { children, visible, hide, title, customClass } = this.props
        return <div className={`modal ${visible ? 'modal-visible' : 'modal-hidden'} ${customClass||""}`}>
            <div className="modal-back" onClick={hide} />
            <div className="modal-suit">
                <div className="modal-close" onClick={hide}><ImageView className="modal-close-ico" source={BUTTONICONS.modalclose}/></div>
                {children}
            </div>
        </div>
    }
}

export { ModalSuit }