import { HandleTask } from '../Base/HandleTask/HandleTask';
import { controllers } from './Controllers';

class NavigatorImpl {
  private _navigation: any;
  private readonly _handle: HandleTask;
  private _navigationMounded: boolean;
  private _saveScreen: Array<string>;
  constructor() {
    this._handle = new HandleTask();
    this._navigationMounded = false;
    this._saveScreen = [];
  }

  get navigationMounted() {
    return this._navigationMounded;
  }

  get handle() {
    return this._handle;
  }

  get navigation() {
    return this._navigation;
  }

  set = (navigation: any) => {
    this._navigation = navigation;
    this._navigationMounded = true;
    this._handle.do();
  };

  back = () => {
    try {
      const findScreen = this._saveScreen[this._saveScreen.length - 2];
      // console.error('MY BCK SCREEN', findScreen);
      navigator().navigate(findScreen);
    } catch (e) {
      console.error('Error back', e);
    }
  };

  navigate = (pageName: string) => {
    if (
      this._navigation === null ||
      this._navigation === undefined
    ) {
      return;
    }
    this.shiftScreen(pageName);
    // this._navigation.navigate(pageName);
    this._navigation.history.push(pageName);
    controllers().scroll.scroll(0)
    // window.location.href = pageName
  };

  shiftScreen = (pageName: string) => {
    this._saveScreen.push(pageName);
    if (this._saveScreen.length > 5) {
      const saveScreen = this._saveScreen;
      saveScreen.shift();
      this._saveScreen = saveScreen;
    }
  };
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.navigator = global.__app__.navigator || new NavigatorImpl();

export function navigator(): NavigatorImpl {
  // @ts-ignore
  return global.__app__.navigator;
}
