import React from "react";
import { OnePage } from "./OnePage";
import { ViewItem } from "../../../Base/ViewItem";
import { CarouselPagging } from "../../../Models/Carousel/CarouselPagging";

type carouselPaginProps = {
    itemsLength: number;
    currentSelected: number;
    press?: (index: number)=>void
}

class CarouselPagin extends ViewItem {
    get controller():CarouselPagging{
        return this.props.controller
    }
    render(){
        const { items } = this.controller
        return <div className="carousel-pagin">
            {items && items.map((op, i)=><OnePage key={i} ref={op.set} controller={op}/>)}
        </div>
    }
}

export {CarouselPagin }