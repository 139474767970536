import React from 'react';

export type viewItemProps = {
  component?: any;
  children?: any;
  RightComponent?: any;
  controller?: any;
  navigation?: any;
  styles?: any;
};

class ViewItem extends React.Component<viewItemProps> {
  update = () => {
    this.forceUpdate();
  };
}

export { ViewItem };
