import React from "react";
import { ICONS } from "../../Constants/icons";
import { ImageView } from "./ImageView";



class Logotype extends React.Component {

    render() {
        return <div className="logo-box">
                <ImageView
                className="logo-icon"
                source={ICONS.justDog}
            />
            <span className="logo-text">Top Dog Coatings</span>
        </div>
    }
}

export { Logotype }