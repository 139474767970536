import React from "react";
import { OnePaggingView } from "./OnePaggingView";

type justPaggingProps = {
    itemsLength: number;
    onPress: (num: number)=>void
}
type justPaggingState = {
    currentSelected: number
}
class JustPagging extends React.Component<justPaggingProps> {
    state: justPaggingState = {
        currentSelected: 0
    }
    setCurrentIndex = (currentSelected: number) => {
        this.setState({currentSelected})
    }
    makeItems = () => {
        let items = []
        for (let index = 0; index < this.props.itemsLength; index++) {
            items.push({number: index, selected: this.state.currentSelected === index})
        }
        return items
    }
    render(){
        const items = this.makeItems()
        return <div className="carousel-pagin">
            {items && items.map((op, i)=><OnePaggingView key={i} num={op.number} selected={op.selected} onPress={this.props.onPress}/>)}
        </div>
    }
}

export { JustPagging }