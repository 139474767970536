import { Modal } from "../../Models/Modal";


class OrderModal extends Modal {
    private _type = ""
    
    show = (type: string) => {
        this._type = type
        this.setVisible(true)
    }

    get type(){
        return this._type
    }
}

export { OrderModal }