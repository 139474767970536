import { Model } from "../../../Base/Model";
import { controllers } from "../../Controllers";

export type oneProjectModel = {
    name: string;
    images: Array<string>
}

class OneProject extends Model {
    private _model: oneProjectModel
    private _selectedIndex: number = 1;

    constructor(model: oneProjectModel){
        super()
        this._model = model
    }

    get name(){
        return this._model.name
    }
    get images(){
        return this._model.images
    }
    get selectedIndex(){
        return this._selectedIndex
    }

    onPress = (number: number) => {
        controllers().modals.photos.show(this.images, number)
    }
}

export { OneProject }