import { ViewItem } from "../../../Base/ViewItem";
import { MenuItem } from "../../../Controllers/Menu/MenuItem";


class MenuItemView extends ViewItem {

    get controller():MenuItem{
        return this.props.controller
    }

    render(){
        const { title, selected, onClick } = this.controller
        return <div className={`menu-item ${selected ? 'menu-selected' : ''}`} onClick={onClick}>
            <span className="menu-title">{title}</span>
        </div>
    }
}

export { MenuItemView }