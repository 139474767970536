import React from 'react'
// import { Fa } from './Fa'


class Loading extends React.Component {
    props: any
    constructor(props:any){
        super(props)
        this.props = props
    }
    render(){
        const {color, size} = this.props
        return <div className="LoaderView">
            {/* <Fa icon="spinner" color={color} size={`${size ? size : '2x'}`} spin/> */}
        </div>
    }
}

export { Loading }