import { ViewItem } from "../../../Base/ViewItem";
import { BUTTONICONS } from "../../../Constants/icons";
import { OneProject } from "../../../Controllers/Pages/Gallery/OneProject";
import { Button } from "../../Components/Button";
import { ImageView } from "../../Components/ImageView";


class OneProjectView extends ViewItem {

    get controller(): OneProject{
        return this.props.controller
    }
    render(){
        const { images, name, onPress} = this.controller
        return <div className="one-project">
            <div className="one-project-view no-scroll">
                {images && images.map((im, i) => <ImageView key={i} className="one-project-image" source={im} onPress={()=>onPress(i)}/>)}
            </div>
            <div className="one-project-nav">
                <span className="one-project-name">{name}</span>
                <div className="solutions-arrows">
                    <Button 
                        onClick={()=>{}}
                        title=""
                        icon={BUTTONICONS.rightAngle}
                        className="icon-button pagi-btn reverse-vertical"
                    />
                    <Button 
                        onClick={()=>{}}
                        title=""
                        icon={BUTTONICONS.rightAngle}
                        className="icon-button pagi-btn"
                    />
                </div>
            </div>
        </div>
    }
}

export { OneProjectView }