import { ViewItem } from "../../Base/ViewItem";
import { BUTTONICONS } from "../../Constants/icons";
import { GetConsultation } from "../../Controllers/Modals/GetConsultation";
import { OrderModal } from "../../Controllers/Modals/OrderModal";
import { PhotosModal } from "../../Controllers/Modals/PhotosModal";
import { Button } from "../Components/Button";
import { ImageView } from "../Components/ImageView";
import { ModalSuit } from "./ModalSuit";


class PhotosModalView extends ViewItem {

    get controller(): PhotosModal {
        return this.props.controller
    }

    render(){
        const { hide, visible, title, photos, scroll } = this.controller
        return <ModalSuit title={title} hide={hide} customClass="photo-modal" visible={visible}>
                <div className="modal-photos no-scroll" ref={scroll.setScrollRef}>
                    {photos && photos.map((op, i) => <div key={i} className="one-modal-photo">
                        <ImageView 
                            className="one-modal-photo-ico"
                            source={op}
                        />
                    </div>)}
                </div>
            </ModalSuit>
    }
}

export { PhotosModalView }